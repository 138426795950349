import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import CloseButton from 'components/Button/CloseButton';
import PointDown from 'components/Icon/PointDown';
import PointLeft from 'components/Icon/PointLeft';
import PointRight from 'components/Icon/PointRight';
import PointUp from 'components/Icon/PointUp';
import { getLanguage } from 'selectors/app';
import { selectClientName, selectLanguages } from 'selectors/config';
import i18n, { LanguageDescription } from 'utils/i18next';

import './styles.scss';

const RoomSelectionMobileTutorialOverlay: React.FC = () => {
    const dispatch = useAppDispatch();

    const [swiperControl, setSwiperControl] = useState<SwiperClass>();
    const [client, setClient] = useState<string>('');
    const [languageCode, setLanguageCode] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);

    const clientName: LanguageDescription[] = useAppSelector(selectClientName);
    const languages: LanguageDescription[] = useAppSelector(selectLanguages);
    const selectedLanguage = useAppSelector<string>(getLanguage);

    const handlePrevButtonClick = () => {
        if (currentPage === 1) {
            setCurrentPage(6);
            return;
        }
        swiperControl?.slidePrev();
        setCurrentPage(currentPage - 1);
    };
    const handleNextButtonClick = () => {
        if (currentPage === 6) {
            dispatch(appActions.setHasShownRoomSelectionTutorial(true));
            return;
        }
        swiperControl?.slideNext();
        setCurrentPage((currentPage % 6) + 1);
    };

    const handleSlideChange = (swiper: SwiperClass) => {
        setCurrentPage(swiper.activeIndex + 1);
    };

    useEffect(() => {
        setClient(`-${clientName[0].client}`);

        const setCurrentLanguageCode = async () => {
            const currentLanguage: string = i18n.getCurrentLanguage();

            const languageDescription: LanguageDescription | undefined =
                languages.find(
                    (language: LanguageDescription): boolean =>
                        language.code === currentLanguage,
                );

            if (languageDescription) {
                setLanguageCode(`-${languageDescription.code}`);
            }
        };
        setCurrentLanguageCode();
    }, [selectedLanguage]);

    return (
        <div className="room-mobile">
            <div
                className={clsx('white-background room-selection', {
                    'landscape-tutorial': currentPage === 6,
                })}
            />
            {currentPage === 6 && (
                <div className="landscape-bottom-background" />
            )}
            <div className="room-selection-mobile-tutorial">
                <CloseButton
                    fill="white"
                    stroke="white"
                    onClick={() => {
                        dispatch(
                            appActions.setHasShownRoomSelectionTutorial(true),
                        );
                    }}
                />
                <div className={clsx('image-container', {})}>
                    <Swiper
                        onInit={(ev) => {
                            setSwiperControl(ev);
                        }}
                        onSlideChange={handleSlideChange}
                    >
                        <SwiperSlide key={1}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-1`} />
                            <div className="tutorial-text text-page-1">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_1</Trans>
                                    </div>
                                    <PointLeft className="point point-left" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-2`} />
                            <div className="tutorial-text text-page-2">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_2</Trans>
                                    </div>
                                    <PointUp className="point point-up" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-3`} />
                            <div className="tutorial-text text-page-3">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_3</Trans>
                                    </div>
                                    <PointDown className="point point-down" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={4}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-4`} />
                            <div className="tutorial-text text-page-4">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_4</Trans>
                                    </div>
                                    <PointRight className="point point-right" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={5}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-5`} />
                            <div className="tutorial-text text-page-5">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_5</Trans>
                                    </div>
                                    <PointDown className="point point-down" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={6}>
                            <div id={`room-selection-tutorial${client}${languageCode}-image-6`} />
                            <div className="tutorial-text text-page-6">
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>ROOM_SELECTION_TUTORIAL_TEXT_1_PAGE_6</Trans>
                                    </div>
                                    <PointUp className="point point-up" />
                                    <PointDown className="point point-down" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={clsx('controls', {})}>
                    <div
                        className={clsx('prev-button', {
                            inactive: currentPage === 1,
                        })}
                        onClick={handlePrevButtonClick}
                        role="presentation"
                    >
                        <Trans>ROOM_SELECTION_TUTORIAL_PREV_BUTTON</Trans>
                    </div>
                    <div className="dots-container">
                        <div
                            className={clsx('dot dot-1', {
                                active: currentPage === 1,
                            })}
                            onClick={() => swiperControl?.slideTo(0)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-2', {
                                active: currentPage === 2,
                            })}
                            onClick={() => swiperControl?.slideTo(1)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-3', {
                                active: currentPage === 3,
                            })}
                            onClick={() => swiperControl?.slideTo(2)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-4', {
                                active: currentPage === 4,
                            })}
                            onClick={() => swiperControl?.slideTo(3)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-5', {
                                active: currentPage === 5,
                            })}
                            onClick={() => swiperControl?.slideTo(4)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-6', {
                                active: currentPage === 6,
                            })}
                            onClick={() => swiperControl?.slideTo(5)}
                            role="presentation"
                        />
                    </div>
                    <div
                        className="next-button"
                        onClick={handleNextButtonClick}
                        role="presentation"
                    >
                        {currentPage !== 6 && (
                            <Trans>ROOM_SELECTION_TUTORIAL_NEXT_BUTTON</Trans>
                        )}
                        {currentPage === 6 && (
                            <Trans>ROOM_SELECTION_TUTORIAL_START_BUTTON</Trans>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoomSelectionMobileTutorialOverlay;
