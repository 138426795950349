import React from 'react';

import RoomSelectionDesktopTutorialOverlay from 'components/RoomSelectionTutorialOverlay/RoomSelectionDesktopTutorialOverlay';
import RoomSelectionMobileTutorialOverlay from 'components/RoomSelectionTutorialOverlay/RoomSelectionMobileTutorialOverlay';
import useViewport, { ViewportContext } from 'hooks/viewport';

const RoomSelectionTutorialOverlay: React.FC = () => {
    const viewport: ViewportContext = useViewport();

    return (
        <>
            {viewport.orientation === 'desktop' && (
                <RoomSelectionDesktopTutorialOverlay />
            )}
            {viewport.orientation !== 'desktop' && (
                <RoomSelectionMobileTutorialOverlay />
            )}
        </>
    );
};

export default RoomSelectionTutorialOverlay;
