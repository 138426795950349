import { ParametersSettingsState } from 'types/parametersSettings';

import { getEnvPathFromParams, getLocaleFromParams } from './params';

// eslint-disable-next-line import/prefer-default-export
export function getParametersSettings(): ParametersSettingsState {
    const params = new URLSearchParams(window.location.search);

    const theSettings: ParametersSettingsState = {
        configPath: getEnvPathFromParams() || '/',
        language: getLocaleFromParams(),
    };

    if (params.has('configPath')) {
        theSettings.configPath = params.get('configPath');
    }

    if (params.has('defaultLocale')) {
        theSettings.language = params.get('defaultLocale');
    }

    if (theSettings.language === null) {
        const theLanguageElem = document.documentElement.lang;

        if (theLanguageElem !== null) {
            theSettings.language = theLanguageElem;
        }
    }

    return theSettings;
}
