import Texture from './texture'

export default class LayoutManager {
    dirty: boolean
    sectionToShaderSegmentMap: {}

    _shaderCode: string
    _layoutData: any

    constructor(data: any) {
        this.dirty = false
        // Map between section type and the corresponding segment in the shader template.
        this.sectionToShaderSegmentMap = {
            header: 'header',
            footer: 'footer',
            main: 'sampler:main',
            mask: 'sampler:mask',
            apply: 'sampler:apply',
            color: 'sampler:color',
            delta: 'sampler:delta',
            uv: 'sampler:uv',
            texture: 'sampler:texture',
            bias: 'sampler:bias',
            zdepth: 'sampler:displace',
        }
        this._layoutData = data
        this.dirty = true
        window['layoutManager'] = this
    }

    public setShaderTemplate(shaderCode: string): void {
        this._shaderCode = shaderCode
    }

    public setColor(id: string, value: string): void {
        var section = this.getSection(id)
        if (section) {
            section.color = value
            this.dirty = true
        }
    }

    public setTexture(
        id: string,
        value: Texture,
        colorizeAmount: number
    ): void {
        if (colorizeAmount === void 0) {
            colorizeAmount = undefined
        }
        var section = this.getSection(id)
        if (section) {
            section.texture = value
            if (colorizeAmount != undefined) {
                section.colorizeAmount = value
            }
            this.dirty = true
        }
    }

    public getSections(): any[] {
        return this._layoutData.layout
    }

    public getSection(id: string): any {
        for (var i: number = 0; i < this._layoutData.layout.length; i++) {
            if (this._layoutData.layout[i].id == id) {
                return this._layoutData.layout[i]
            }
        }
        return null
    }

    public getNormalizedRect(rect: any[]): any[] {
        return [
            rect[0] / this._layoutData.width,
            rect[1] / this._layoutData.height,
            rect[2] / this._layoutData.width,
            rect[3] / this._layoutData.height,
        ]
    }

    public generateShaderCode(): string {
        var shaderSegments = this.splitSegments(this._shaderCode)
        var shader: any[] = []
        var addSection = (sectionType, sectionData) => {
            var shaderSegmentId = this.sectionToShaderSegmentMap[sectionType]
            if (!shaderSegmentId) {
                console.error(
                    "Unknown section '%s' encountered in the shader configuration.",
                    sectionType
                )
            } else {
                var shaderSegment = shaderSegments[shaderSegmentId]
                if (!shaderSegment) {
                    console.error(
                        "Segment '%s' was not found in the shader.",
                        shaderSegmentId
                    )
                } else {
                    shader.push(
                        this.processTemplate(shaderSegment, sectionData)
                    )
                }
            }
        }
        // Enable contrast adjustment if requested.
        // Checking for 0 here removes the need for a check in the shader.
        if (this._layoutData.contrast && this._layoutData.contrast > 0) {
            shader.push(
                '#define CONTRAST_ADJUSTMENT\n' +
                    '#define CONTRAST_STRENGTH ' +
                    this.numberToString(this._layoutData.contrast || 0.0) +
                    '\n' +
                    '#define CONTRAST_CENTER ' +
                    this.numberToString(
                        this._layoutData.contrastCenter || 0.5
                    ) +
                    '\n'
            )
        }
        // Define reference colors when specified.
        if (
            this._layoutData.blackReference ||
            this._layoutData.whiteReference
        ) {
            var blackReference = this.colorStringToVector(
                this._layoutData.blackReference || '000000'
            )
            var whiteReference = this.colorStringToVector(
                this._layoutData.whiteReference || 'ffffff'
            )
            shader.push(
                '#define REFERENCE_COLOR_ADJUSTMENT\n' +
                    '#define BLACK_REFERENCE vec3(' +
                    this.numberToString(blackReference[0]) +
                    ', ' +
                    this.numberToString(blackReference[1]) +
                    ', ' +
                    this.numberToString(blackReference[2]) +
                    ')\n' +
                    '#define WHITE_REFERENCE vec3(' +
                    this.numberToString(whiteReference[0]) +
                    ', ' +
                    this.numberToString(whiteReference[1]) +
                    ', ' +
                    this.numberToString(whiteReference[2]) +
                    ')\n'
            )
        }
        addSection('header', null)
        addSection('main', this._layoutData.main)
        for (var i: number = 0; i < this._layoutData.layout.length; i++) {
            var section = this._layoutData.layout[i]
            if (!section.color) {
                section.color = '808080'
            }
            addSection(section.type || 'color', section)
        }
        addSection('footer', null)
        return shader.join('\n')
    }

    public updateMaterial(uniforms: any[]): void {
        for (var i: number = 0; i < this._layoutData.layout.length; i++) {
            var section = this._layoutData.layout[i]
            uniforms['u_color_' + section.id] = this.colorStringToVector(
                section.color
            )
            if (section.type == 'texture') {
                var theTexture = section.texture
                uniforms['u_texture_' + section.id] = theTexture
                    ? theTexture.texture
                    : null
                uniforms['u_colorizeAmount_' + section.id] =
                    section.colorizeAmount != undefined
                        ? section.colorizeAmount
                        : 1.0
            }
        }
    }

    public processTemplate(code: string, section: any): string {
        if (section === void 0) {
            section = null
        }
        var replaceNumber = (
            code: string,
            key: string,
            value: number
        ): string => {
            return code.split('{' + key + '}').join(this.numberToString(value))
        }
        var replaceString = (
            code: string,
            key: string,
            value: string
        ): string => {
            return code.split('{' + key + '}').join(value)
        }
        code = replaceNumber(code, 'vwidth', this._layoutData.width)
        code = replaceNumber(code, 'vheight', this._layoutData.height)
        if (section) {
            code = replaceString(code, 'sectionname', section.name)
            if (section.rect) {
                var rect = this.getNormalizedRect(section.rect)
                code = replaceNumber(code, 'sx', rect[0])
                code = replaceNumber(code, 'sy', rect[1])
                code = replaceNumber(code, 'swidth', rect[2])
                code = replaceNumber(code, 'sheight', rect[3])
            }
            var rect = this.getNormalizedRect(this._layoutData.main.rect)
            code = replaceNumber(code, 'mx', rect[0])
            code = replaceNumber(code, 'my', rect[1])
            code = replaceNumber(code, 'mwidth', rect[2])
            code = replaceNumber(code, 'mheight', rect[3])
            code = replaceString(code, 'channel', section.channel)
            code = replaceString(code, 'uv', section.uv)
            code = replaceString(code, 'id', section.id)
            code = replaceString(code, 'color', 'u_color_' + section.id)
            code = replaceString(code, 'texture', 'u_texture_' + section.id)
            code = replaceString(
                code,
                'colorizeAmount',
                'u_colorizeAmount_' + section.id
            )
        }
        var colorUniforms: string = ''
        for (var i: number = 0; i < this._layoutData.layout.length; i++) {
            colorUniforms +=
                'uniform vec4 u_color_' + this._layoutData.layout[i].id + ';\n'
        }
        code = replaceString(code, 'colors', colorUniforms)
        var textureUniforms: string = ''
        for (var i: number = 0; i < this._layoutData.layout.length; i++) {
            if (this._layoutData.layout[i].type == 'texture') {
                textureUniforms +=
                    'uniform sampler2D u_texture_' +
                    this._layoutData.layout[i].id +
                    ';\n'
                textureUniforms +=
                    'uniform float u_colorizeAmount_' +
                    this._layoutData.layout[i].id +
                    ';\n'
            }
        }
        code = replaceString(code, 'textures', textureUniforms)
        return code
    }

    public splitSegments(code: any): {} {
        var data: {} = {}
        var lines: string = code.split('\r').join('').split('\n')
        var currentSegment: string = ''
        for (var i: number = 0; i < lines.length; i++) {
            if (lines[i].substr(0, 3) == '###') {
                currentSegment = lines[i].substr(3).toLowerCase().trim()
                data[currentSegment] = ''
            } else {
                if (data[currentSegment] != '') {
                    data[currentSegment] += '\n'
                }
                data[currentSegment] += lines[i]
            }
        }
        return data
    }

    public numberToString(value: number): string {
        var val: string = value.toString()
        if (val.indexOf('.') == -1) val += '.0'
        return val
    }

    public colorStringToVector(value: string): number[] {
        var r: number = parseInt(value.substr(0, 2), 16) / 255
        var g: number = parseInt(value.substr(2, 2), 16) / 255
        var b: number = parseInt(value.substr(4, 2), 16) / 255
        return [r, g, b, 1.0]
    }
}
