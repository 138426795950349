import React from 'react';

import './styles.scss';
import { useAppSelector } from 'app/hook';
import LanguageButton from 'components/Button/LanguageButton';
import { selectLanguages } from 'selectors/config';
import { LanguageDescription } from 'utils/i18next';

const LanguageSelector: React.FC = () => {
    const languages: LanguageDescription[] =
        useAppSelector<LanguageDescription[]>(selectLanguages);

    return (
        <div className="language-selector">
            {languages.map((language: LanguageDescription) => (
                <LanguageButton
                    key={language.code}
                    code={language.code}
                    label={language.name}
                />
            ))}
        </div>
    );
};

export default LanguageSelector;
