import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Config, ConfigState } from 'types/config';
import { LanguageDescription } from 'utils/i18next';

const initialState: ConfigState = {
    fullConfig: {
        i18n: {},
        Colors: [],
        Collections: [],
        ColorFamilies: [],
        Rooms: [],
    },
    languages: [],
    clientName: [],
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<Config>) => {
            state.fullConfig = action.payload;
        },
        setLanguages: (state, action: PayloadAction<LanguageDescription[]>) => {
            state.languages = action.payload;
        },
    },
});

export default configSlice.reducer;
