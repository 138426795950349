import React, { useEffect, useState } from 'react';

import appActions from 'actions/app';
import configActions from 'actions/config';
import { useAppDispatch, useAppSelector } from 'app/hook';
import Loader from 'components/Loader';
import { selectParametersSettingsLanguage } from 'selectors/parametersSettings';
import { ErrorType } from 'utils/error';
import i18n, { LanguageDescription } from 'utils/i18next';
import { getLocaleFromParams } from 'utils/params';

const ConfigLoader: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const dispatch = useAppDispatch();
    const languageParameter = useAppSelector(selectParametersSettingsLanguage);

    // Init language from params
    useEffect(() => {
        const lang = getLocaleFromParams();

        if (isLoaded && lang) {
            i18n.changeLanguage(lang);
            dispatch(appActions.setLanguage(lang));
        }
    }, [isLoaded]);

    // Setting the correct language for localization
    useEffect(() => {
        if (
            !!languageParameter &&
            isLoaded &&
            i18n.getCurrentLanguage() !== languageParameter
        ) {
            i18n.changeLanguage(languageParameter);
            dispatch(appActions.setLanguage(languageParameter));
        }
    }, [languageParameter, isLoaded]);

    useEffect(() => {
        const loadConfig = async () => {
            const result = await configActions.get();

            if ('data' in result && result.data) {
                i18n.init(result.data);
                dispatch(configActions.setConfig(result.data));

                const languages: LanguageDescription[] = i18n.getLanguages(
                    result.data,
                );
                dispatch(configActions.setLanguages(languages));
            }

            if ('errorMessage' in result && result.errorMessage) {
                dispatch(
                    appActions.setError({
                        title: 'Unknown error',
                        text: 'Error while loading the configuration file',
                        type: ErrorType.UNKNOWN,
                    }),
                );
                setHasError(true);
            }

            setIsLoaded(true);
        };

        loadConfig();
    }, [dispatch]);

    if (!isLoaded) {
        return <Loader />;
    }

    if (hasError) {
        return null;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

export default ConfigLoader;
