export default class Program {
    _gl: WebGLRenderingContext
    _program: any
    _uniformSetters: {}
    _attributeSetters: {}

    constructor(gl: any, shaders: any) {
        this._gl = gl
        this._program = gl.createProgram()
        this._uniformSetters = {}
        this._attributeSetters = {}
        if (shaders) {
            this.setShaders(shaders)
        }
    }

    /**
     * Creates a Program using the specified vertex and fragment shader code.
     */
    public static createProgram(
        gl: any,
        vertexSource: string,
        fragmentSource: string
    ): Program {
        var vertexShader = this.createShader(gl, gl.VERTEX_SHADER, vertexSource)
        var fragmentShader = this.createShader(
            gl,
            gl.FRAGMENT_SHADER,
            fragmentSource
        )
        var program = new Program(gl, [vertexShader, fragmentShader])
        gl.deleteShader(vertexShader)
        gl.deleteShader(fragmentShader)
        return program
    }

    /**
     * Creates and compiles a WebGLShader using the specified source.
     */
    public static createShader(gl: any, type: string, source: string): any {
        var shader = gl.createShader(type)
        gl.shaderSource(shader, source)
        gl.compileShader(shader)
        if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
            console.error(
                'Error compiling shader: ' + gl.getShaderInfoLog(shader)
            )
        }
        return shader
    }

    public get attributes() {
        return this._attributeSetters
    }

    public get program() {
        return this._program
    }

    public get linked() {
        return this._gl.getProgramParameter(this._program, this._gl.LINK_STATUS)
    }

    public get uniforms() {
        return this._uniformSetters
    }

    /**
     * Initializes the program with the specified shaders.
     */
    public setShaders(shaders: any[]): void {
        var gl: WebGLRenderingContext = this._gl
        var program = this._program
        for (var i: number = 0; i < shaders.length; i++) {
            gl.attachShader(program, shaders[i])
        }
        gl.linkProgram(program)
        if (!this.linked) {
            console.error(
                'Error linking program: ' + gl.getProgramInfoLog(program)
            )
        }
        this._uniformSetters = this.createUniformSetters()
        this._attributeSetters = this.createAttributeSetters()
        for (var i: number = 0; i < shaders.length; i++) {
            gl.detachShader(program, shaders[i])
        }
    }

    /**
     * Sets program uniforms and binds related textures.
     */
    public setUniforms(uniformData: any[]): void {
        var setters = this._uniformSetters
        Object.keys(uniformData).forEach((name) => {
            var setter = setters[name]
            if (setter) {
                setter(uniformData[name])
            }
        })
    }

    /**
     * Sets program attributes.
     */
    public setAttributes(attributeData): void {
        var attributeSetters = this._attributeSetters
        Object.keys(attributeData.attributes).forEach((name) => {
            var setter = attributeSetters[name]
            if (setter) {
                setter(attributeData.attributes[name])
            }
        })
        if (attributeData.indices) {
            this._gl.bindBuffer(
                this._gl.ELEMENT_ARRAY_BUFFER,
                attributeData.indices
            )
        }
    }

    /**
     * Dispose of unmanaged resources.
     */
    public dispose(): void {
        if (this._program) {
            this._gl.deleteProgram(this._program)
            this._program = null
        }
    }

    /**
     * Creates setter functions for the program uniforms.
     */
    public createUniformSetters() {
        var gl = this._gl
        var program = this._program
        var textureUnit: number = 0

        const createUniformSetter = (uniformInfo): Function => {
            var location = gl.getUniformLocation(program, uniformInfo.name)
            var type: number = uniformInfo.type
            var isArray: boolean =
                uniformInfo.size > 1 && uniformInfo.name.substr(-3) === '[0]'
            switch (type) {
                case gl.FLOAT:
                    return isArray
                        ? (v) => {
                              return gl.uniform1fv(location, v)
                          }
                        : (v) => {
                              return gl.uniform1f(location, v)
                          }
                case gl.FLOAT_VEC2:
                    return (v) => {
                        return gl.uniform2fv(location, v)
                    }
                case gl.FLOAT_VEC3:
                    return (v) => {
                        return gl.uniform3fv(location, v)
                    }
                case gl.FLOAT_VEC4:
                    return (v) => {
                        return gl.uniform4fv(location, v)
                    }
                case gl.INT:
                case gl.BOOL:
                    return isArray
                        ? (v) => {
                              return gl.uniform1iv(location, v)
                          }
                        : (v) => {
                              return gl.uniform1i(location, v)
                          }
                case gl.INT_VEC2:
                case gl.BOOL_VEC2:
                    return (v) => {
                        return gl.uniform2iv(location, v)
                    }
                case gl.INT_VEC3:
                case gl.BOOL_VEC3:
                    return (v) => {
                        return gl.uniform3iv(location, v)
                    }
                case gl.INT_VEC4:
                case gl.BOOL_VEC4:
                    return (v) => {
                        return gl.uniform4iv(location, v)
                    }
                case gl.FLOAT_MAT2:
                    return (v) => {
                        return gl.uniformMatrix2fv(location, false, v)
                    }
                case gl.FLOAT_MAT3:
                    return (v) => {
                        return gl.uniformMatrix3fv(location, false, v)
                    }
                case gl.FLOAT_MAT4:
                    return (v) => {
                        return gl.uniformMatrix4fv(location, false, v)
                    }
                case gl.SAMPLER_2D:
                case gl.SAMPLER_CUBE:
                    if (isArray) {
                        var units: number[] = []
                        for (var i = 0; i < uniformInfo.size; ++i) {
                            units.push(textureUnit++)
                        }
                        return ((bindPoint, units) => {
                            return (textures) => {
                                gl.uniform1iv(location, units)
                                textures.forEach((texture, index) => {
                                    gl.activeTexture(gl.TEXTURE0 + units[index])
                                    gl.bindTexture(bindPoint, texture)
                                })
                            }
                        })(
                            type === gl.SAMPLER_2D
                                ? gl.TEXTURE_2D
                                : gl.TEXTURE_CUBE_MAP,
                            units
                        )
                    } else {
                        return ((bindPoint, unit) => {
                            return (texture) => {
                                gl.uniform1i(location, unit)
                                gl.activeTexture(gl.TEXTURE0 + unit)
                                gl.bindTexture(bindPoint, texture)
                            }
                        })(
                            type === gl.SAMPLER_2D
                                ? gl.TEXTURE_2D
                                : gl.TEXTURE_CUBE_MAP,
                            textureUnit++
                        )
                    }
                default:
                    throw 'unknown type: 0x' + type.toString()
            }
        }

        var uniformSetters: {} = {}
        var numUniforms = gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS)
        for (var i: number = 0; i < numUniforms; ++i) {
            var uniformInfo = gl.getActiveUniform(program, i)
            if (uniformInfo) {
                var name = uniformInfo.name
                if (name.substr(-3) === '[0]') {
                    name = name.substr(0, name.length - 3)
                }
                uniformSetters[name] = createUniformSetter(uniformInfo)
            }
        }
        return uniformSetters
    }

    /**
     * Creates setter functions for the program attributes.
     */
    public createAttributeSetters() {
        var gl: WebGLRenderingContext = this._gl
        var program = this._program

        const createAttributeSetter = (attributeInfo): Function => {
            var location = gl.getAttribLocation(program, attributeInfo.name)
            return (b) => {
                gl.bindBuffer(gl.ARRAY_BUFFER, b.buffer)
                gl.enableVertexAttribArray(location)
                gl.vertexAttribPointer(
                    location,
                    b.size,
                    b.type || gl.FLOAT,
                    b.normalize || false,
                    b.stride || 0,
                    b.offset || 0
                )
            }
        }
        var attributeSetters: {} = {}
        var numAttributes = gl.getProgramParameter(
            program,
            gl.ACTIVE_ATTRIBUTES
        )
        for (var i = 0; i < numAttributes; ++i) {
            var attributeInfo = gl.getActiveAttrib(program, i)
            if (attributeInfo) {
                attributeSetters[attributeInfo.name] =
                    createAttributeSetter(attributeInfo)
            }
        }
        return attributeSetters
    }
}
