import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const Close: React.FC<IconProps> = ({
    className,
    height = 12.73,
    opacity,
    width = 12.73,
    fill,
    stroke,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 12.73 12.73"
        width={width}
    >
        <path
            d="M1.11377 0.707081L6.01085 5.60423L6.3644 5.95778L6.71795 5.60424L11.615 0.707229L12.0217 1.1139L7.12462 6.01091L6.77106 6.36445L7.12462 6.71801L12.0217 11.6152L11.615 12.0218L6.71795 7.12467L6.3644 6.77111L6.01085 7.12466L1.11377 12.0217L0.707107 11.615L5.60418 6.71799L5.95774 6.36444L5.60418 6.01088L0.707107 1.11374L1.11377 0.707081Z"
            fill={fill}
            fillOpacity={opacity}
            stroke={stroke}
        />
    </IconBase>
);

export default Close;
