import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import BackButton from 'components/Button/BackButton';
import { getIsColorPalettePage, getLanguage } from 'selectors/app';
import './styles.scss';

const NavigationPanel: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const isColorPalettePage: boolean = useAppSelector<boolean>(
        getIsColorPalettePage,
    );
    const language: string = useAppSelector<string>(getLanguage);
    const [label, setLabel] = useState<string | null>('');

    useEffect(() => {
        const navigationPanelLabel: string = isColorPalettePage
            ? 'COLOR_PALETTE_SELECT_A_COLOR_FROM_THE_COLLECTION'
            : 'ROOM_SELECT_A_SUITABLE_PALLET';

        setLabel(t(navigationPanelLabel));
    }, [isColorPalettePage, language]);

    return (
        <div className="navigation-panel-container">
            {!isColorPalettePage && (
                <BackButton
                    className="back"
                    onClick={() => {
                        dispatch(appActions.setIsColorPalettePage(true));
                    }}
                />
            )}

            <div className="navigation-panel-title">{label}</div>
        </div>
    );
};

export default NavigationPanel;
