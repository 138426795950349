import React from 'react';

import clsx from 'clsx';

import './styles.scss';

export type ButtonProps = {
    children?: React.ReactNode;
    className?: string;
    isDisabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Button: React.FC<ButtonProps> = ({
    children,
    className,
    isDisabled,
    onClick,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!onClick) {
            return;
        }
        onClick(event);
    };

    return (
        <button
            className={clsx('button', className)}
            disabled={isDisabled}
            onClick={handleClick}
            style={{}}
            type="button"
        >
            {children}
        </button>
    );
};

Button.defaultProps = {
    children: undefined,
    className: undefined,
    isDisabled: false,
    onClick: undefined,
};

export default Button;
