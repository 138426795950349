import React, { useState } from 'react';

import { Trans } from 'react-i18next';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import ColorBox from 'components/ColorBox';
import { selectColorConfig } from 'selectors/config';
import { ColorConfig } from 'types/config';

import './styles.scss';

type ColorSchemeProps = {
    colorID: string | undefined;
}

const ColorScheme: React.FC<ColorSchemeProps> = ({
    colorID,
}) => {
    const dispatch = useAppDispatch();

    const [isTonalRefresh, setTonalRefresh] = useState<boolean>(false);
    const [isNeutralRefresh, setNeutralRefresh] = useState<boolean>(false);
    const [isDesignerRefresh, setDesignerRefresh] = useState<boolean>(false);
    const [tonalIndexValue, setTonalIndexValue] = useState<number[]>([0, 1]);
    const [neutralIndexValue, setNeutralIndexValue] = useState<number[]>([0, 1]);
    const [designerIndexValue, setDesignerIndexValue] = useState<number[]>([0, 1]);
    const [tonalColorizerIndex, setTonalColorizerIndex] = useState<number[]>([0, 2]);
    const [neutralColorizerIndex, setNeutralColorizerIndex] = useState<number[]>([0, 2]);
    const [designerColorizerIndex, setDesignerColorizerIndex] = useState<number[]>([0, 2]);

    const selectedColor: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(colorID));

    const lightWallColor1: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Tonal && selectedColor?.Matches.Tonal[0] ?
                selectedColor?.Matches.Tonal[0].toString() : selectedColor?.ID,
        ));

    const darkWallColor1: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Tonal && selectedColor?.Matches.Tonal[1] ?
                selectedColor?.Matches.Tonal[1].toString() : selectedColor?.ID,
        ));

    const lightWallColor2: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Neutral && selectedColor?.Matches.Neutral[0] ?
                selectedColor?.Matches.Neutral[0].toString() : selectedColor?.ID,
        ));

    const darkWallColor2: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Neutral && selectedColor?.Matches.Neutral[1] ?
                selectedColor?.Matches.Neutral[1].toString() : selectedColor?.ID,
        ));

    const lightWallColor3: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Designer && selectedColor?.Matches.Designer[0] ?
                selectedColor?.Matches.Designer[0].toString() : selectedColor?.ID,
        ));

    const darkWallColor3: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
        >(selectColorConfig(
            selectedColor?.Matches.Designer && selectedColor?.Matches.Designer[1] ?
                selectedColor?.Matches.Designer[1].toString() : selectedColor?.ID,
        ));

    const handleClickAllWalls = () => {
        if (selectedColor) {
            dispatch(appActions.setMainColorID(selectedColor.ID));
            dispatch(appActions.setLightColorID(selectedColor.ID));
            dispatch(appActions.setDarkColorID(selectedColor.ID));
        }
        dispatch(appActions.setWallSectionColor({ colorRGB: selectedColor?.RGB, wallIndex: 0 }));
        dispatch(appActions.setWallSectionColor({ colorRGB: selectedColor?.RGB, wallIndex: 1 }));
        dispatch(appActions.setWallSectionColor({ colorRGB: selectedColor?.RGB, wallIndex: 2 }));
    };

    const handleClick = (rgb: string | undefined, index:number) => {
        dispatch(appActions.setWallSectionColor({
            colorRGB: rgb, wallIndex: index,
        }));
    };

    const handleClickTonal = () => {
        if (selectedColor) {
            dispatch(appActions.setMainColorID(selectedColor.ID));
            dispatch(appActions.setLightColorID(
                selectedColor?.Matches.Tonal &&
                selectedColor?.Matches.Tonal[0]
                    ?
                    selectedColor?.Matches.Tonal[tonalIndexValue[0]]
                        .toString()
                    :
                    selectedColor?.ID.toString(),
            ));
            dispatch(appActions.setDarkColorID(
                selectedColor?.Matches.Tonal &&
                selectedColor?.Matches.Tonal[1]
                    ?
                    selectedColor?.Matches.Tonal[tonalIndexValue[1]]
                        .toString()
                    :
                    selectedColor?.ID.toString(),
            ));
        }
        handleClick(lightWallColor1?.RGB, tonalColorizerIndex[0]);
        handleClick(darkWallColor1?.RGB, tonalColorizerIndex[1]);
    };

    const handleClickNeutral = () => {
        if (selectedColor) {
            dispatch(appActions.setMainColorID(selectedColor.ID));
            dispatch(appActions.setLightColorID(
                selectedColor?.Matches.Neutral &&
                selectedColor?.Matches.Neutral[0]
                    ?
                    selectedColor?.Matches.Neutral[neutralIndexValue[0]].toString()
                    :
                    selectedColor?.ID.toString(),
            ));
            dispatch(appActions.setDarkColorID(
                selectedColor?.Matches.Neutral &&
                selectedColor?.Matches.Neutral[1]
                    ?
                    selectedColor?.Matches.Neutral[neutralIndexValue[1]].toString()
                    :
                    selectedColor?.ID.toString(),
            ));
        }
        handleClick(lightWallColor2?.RGB, neutralColorizerIndex[0]);
        handleClick(darkWallColor2?.RGB, neutralColorizerIndex[1]);
    };

    const handleClickDesigner = () => {
        if (selectedColor) {
            dispatch(appActions.setMainColorID(selectedColor.ID));
            dispatch(appActions.setLightColorID(
                selectedColor?.Matches.Designer &&
                selectedColor?.Matches.Designer[0]
                    ?
                    selectedColor?.Matches.Designer[designerIndexValue[0]]
                        .toString()
                    :
                    selectedColor?.ID.toString(),
            ));
            dispatch(appActions.setDarkColorID(
                selectedColor?.Matches.Designer &&
                selectedColor?.Matches.Designer[1]
                    ?
                    selectedColor?.Matches.Designer[designerIndexValue[1]]
                        .toString()
                    :
                    selectedColor?.ID.toString(),
            ));
        }
        handleClick(lightWallColor3?.RGB, designerColorizerIndex[0]);
        handleClick(darkWallColor3?.RGB, designerColorizerIndex[1]);
    };

    const removeClassNames = () => {
        const blankRoomTitle = document.getElementById('blank-room-title');
        const tonalTitle = document.getElementById('tonal-title');
        const neutralTitle = document.getElementById('neutral-title');
        const designerTitle = document.getElementById('designers-choice-title');
        if (blankRoomTitle && tonalTitle && neutralTitle && designerTitle) {
            blankRoomTitle.classList.remove('grey');
            tonalTitle.classList.remove('grey');
            neutralTitle.classList.remove('grey');
            designerTitle.classList.remove('grey');
        }
    };

    const handleButtonSectionClick = (classname: string) => {
        removeClassNames();
        const div = document.getElementById(classname);
        div?.classList.add('grey');
    };

    const handleRefreshTonalClick = () => {
        if (!isTonalRefresh) {
            setTonalColorizerIndex([2, 0]);
            setTonalIndexValue([1, 0]);
            setTonalRefresh(!isTonalRefresh);
            dispatch(appActions.setLightColorID(darkWallColor1?.ID || ''));
            dispatch(appActions.setDarkColorID(lightWallColor1?.ID || ''));
        } else {
            setTonalColorizerIndex([0, 2]);
            setTonalIndexValue([0, 1]);
            setTonalRefresh(!isTonalRefresh);
            dispatch(appActions.setLightColorID(lightWallColor1?.ID || ''));
            dispatch(appActions.setDarkColorID(darkWallColor1?.ID || ''));
        }
        dispatch(appActions.setWallSectionColor({
            colorRGB: lightWallColor1?.RGB, wallIndex: tonalColorizerIndex[1],
        }));
        dispatch(appActions.setWallSectionColor({
            colorRGB: darkWallColor1?.RGB, wallIndex: tonalColorizerIndex[0],
        }));
    };

    const handleRefreshNeutralClick = () => {
        if (!isNeutralRefresh) {
            setNeutralColorizerIndex([2, 0]);
            setNeutralIndexValue([1, 0]);
            setNeutralRefresh(!isNeutralRefresh);
            dispatch(appActions.setLightColorID(darkWallColor2?.ID || ''));
            dispatch(appActions.setDarkColorID(lightWallColor2?.ID || ''));
        } else {
            setNeutralColorizerIndex([0, 2]);
            setNeutralIndexValue([0, 1]);
            setNeutralRefresh(!isNeutralRefresh);
            dispatch(appActions.setLightColorID(lightWallColor2?.ID || ''));
            dispatch(appActions.setDarkColorID(darkWallColor2?.ID || ''));
        }
        dispatch(appActions.setWallSectionColor({
            colorRGB: lightWallColor2?.RGB, wallIndex: neutralColorizerIndex[1],
        }));
        dispatch(appActions.setWallSectionColor({
            colorRGB: darkWallColor2?.RGB, wallIndex: neutralColorizerIndex[0],
        }));
    };

    const handleRefreshDesignerClick = () => {
        if (!isDesignerRefresh) {
            setDesignerColorizerIndex([2, 0]);
            setDesignerIndexValue([1, 0]);
            setDesignerRefresh(!isDesignerRefresh);
            dispatch(appActions.setLightColorID(darkWallColor3?.ID || ''));
            dispatch(appActions.setDarkColorID(lightWallColor3?.ID || ''));
        } else {
            setDesignerColorizerIndex([0, 2]);
            setDesignerIndexValue([0, 1]);
            setDesignerRefresh(!isDesignerRefresh);
            dispatch(appActions.setLightColorID(lightWallColor3?.ID || ''));
            dispatch(appActions.setDarkColorID(darkWallColor3?.ID || ''));
        }
        dispatch(appActions.setWallSectionColor({
            colorRGB: lightWallColor3?.RGB, wallIndex: designerColorizerIndex[1],
        }));
        dispatch(appActions.setWallSectionColor({
            colorRGB: darkWallColor3?.RGB, wallIndex: designerColorizerIndex[0],
        }));
    };

    return (
        <div className="color-scheme-container">
            <div
                className="blank-room color-box-container div-container"
                onClick={() => handleButtonSectionClick('blank-room-title')}
                role="presentation"
            >
                <div className="blank-room-title" id="blank-room-title" onClick={handleClickAllWalls} role="presentation">
                    <Trans>ROOM_CHOSEN_COLOR</Trans>
                </div>
                <div className="w-33 mt-20 m-auto">
                    <ColorBox
                        colorID={colorID}
                        onWallClick={handleClickAllWalls}
                    />
                </div>
            </div>
            <div
                className="tonal-container div-container"
                onClick={() => handleButtonSectionClick('tonal-title')}
                role="presentation"
            >
                <div className="tonal-title" id="tonal-title" onClick={handleClickTonal} role="presentation">
                    <Trans>ROOM_MATCHING_COLORS</Trans>
                </div>
                <div className="flex mt-11 color-box-container">
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Tonal &&
                                selectedColor?.Matches.Tonal[tonalIndexValue[0]]
                                    ?
                                    selectedColor?.Matches.Tonal[tonalIndexValue[0]]
                                        .toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickTonal}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={colorID}
                            onWallClick={handleClickTonal}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Tonal &&
                                selectedColor?.Matches.Tonal[tonalIndexValue[1]]
                                    ?
                                    selectedColor?.Matches.Tonal[tonalIndexValue[1]]
                                        .toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickTonal}
                        />
                    </div>
                    <div
                        className="refresh-icon"
                        onClick={(handleRefreshTonalClick)}
                        role="presentation"
                    />
                </div>
            </div>
            <div
                className="neutral-container div-container"
                onClick={() => handleButtonSectionClick('neutral-title')}
                role="presentation"
            >
                <div className="neutral-title" id="neutral-title" onClick={handleClickNeutral} role="presentation">
                    <Trans>ROOM_NEUTRAL_COLORS</Trans>
                </div>
                <div className="flex mt-11 color-box-container">
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Neutral &&
                                selectedColor?.Matches.Neutral[neutralIndexValue[0]]
                                    ?
                                    selectedColor?.Matches.Neutral[neutralIndexValue[0]].toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickNeutral}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={colorID}
                            onWallClick={handleClickNeutral}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Neutral &&
                                selectedColor?.Matches.Neutral[neutralIndexValue[1]]
                                    ?
                                    selectedColor?.Matches.Neutral[neutralIndexValue[1]].toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickNeutral}
                        />
                    </div>
                    <div
                        className="refresh-icon"
                        onClick={(handleRefreshNeutralClick)}
                        role="presentation"
                    />
                </div>
            </div>
            <div
                className="designers-choice-container div-container"
                onClick={() => handleButtonSectionClick('designers-choice-title')}
                role="presentation"
            >
                <div className="designers-choice-title" id="designers-choice-title" onClick={handleClickDesigner} role="presentation">
                    <Trans>ROOM_COLORFUL_COLORS</Trans>
                </div>
                <div className="flex mt-11 color-box-container">
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Designer &&
                                selectedColor?.Matches.Designer[designerIndexValue[0]]
                                    ?
                                    selectedColor?.Matches.Designer[designerIndexValue[0]]
                                        .toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickDesigner}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={colorID}
                            onWallClick={handleClickDesigner}
                        />
                    </div>
                    <div className="w-33 m-auto">
                        <ColorBox
                            colorID={
                                selectedColor?.Matches.Designer &&
                                selectedColor?.Matches.Designer[designerIndexValue[1]]
                                    ?
                                    selectedColor?.Matches.Designer[designerIndexValue[1]]
                                        .toString()
                                    :
                                    selectedColor?.ID.toString()
                            }
                            onWallClick={handleClickDesigner}
                        />
                    </div>
                    <div
                        className="refresh-icon"
                        onClick={(handleRefreshDesignerClick)}
                        role="presentation"
                    />
                </div>
            </div>
        </div>
    );
};

export default ColorScheme;
