import { LoadedResources } from './models'

export default class ResourceLoader {
    public static loadResources(uriList, delegate: (value: LoadedResources) => void): void {
        var resultList: LoadedResources = {}
        var loadList = uriList.slice()
        var loadNext = (): void => {
            var uri = loadList.shift()
            var nlib = window['nlib']
            if (uri && nlib) {
                nlib.SimpleLoader.load(uri, (result): void => {
                    // if the result was an image then it was a special compressed datafile
                    if (result.substr(1, 3) == 'PNG') {
                        var img: HTMLImageElement =
                            document.createElement('img')
                        img.onload = (): void => {
                            var bytecanvas: HTMLCanvasElement =
                                document.createElement('canvas')
                            var bytecontext: CanvasRenderingContext2D | null =
                                bytecanvas.getContext('2d', {willReadFrequently: true})
                            bytecanvas.width = img.width
                            bytecanvas.height = img.height
                            bytecontext?.drawImage(img, 0, 0)
                            var rgbdata: Uint8ClampedArray | undefined =
                                bytecontext?.getImageData(
                                    0,
                                    0,
                                    bytecanvas.width,
                                    bytecanvas.height
                                ).data
                            var bytedata: any[] = []
                            // The color reconstruction may not exact (e.g. Android devices); the image pallette allows for some recovering:
                            // r = 64 * val, g = 32 * (val / 4), b = 32 * (val / 32). The data is biased and rounded here to obtain the original values.
                            var numBytes: number
                            if (rgbdata) {
                                numBytes = rgbdata.length / 4
                                for (var i: number = 0; i < numBytes; i++) {
                                    bytedata[i] =
                                        ((rgbdata[i * 4] + 32) >> 6) +
                                        (((rgbdata[i * 4 + 1] + 16) >> 5) <<
                                            2) +
                                        (((rgbdata[i * 4 + 2] + 16) >> 5) << 5)
                                }
                                // read as many files as are packed in our compressed file catalog
                                while (bytedata[0]) {
                                    // read first byte for the filename length
                                    var namesize: number | undefined =
                                        bytedata.shift()
                                    var filename: string = ''
                                    if (namesize) {
                                        for (
                                            var i: number = 0;
                                            i < namesize;
                                            i++
                                        ) {
                                            filename += String.fromCharCode(
                                                bytedata.shift()
                                            )
                                        }
                                    }
                                    // read 4 bytes (32-bit word) for the file-content length
                                    var filesize: number = 0
                                    var result: string = ''
                                    for (var i: number = 0; i < 4; i++) {
                                        filesize =
                                            filesize * 256 + bytedata.shift()
                                    }
                                    // filesize sanity check, it could be wrong (huge) if the data contains errors.
                                    if (filesize > numBytes) {
                                        console.error(
                                            'Error decoding data: invalid size'
                                        )
                                        break
                                    }
                                    // read until we have the bytelength from the header or until the image stops
                                    for (var i: number = 0; i < filesize; i++) {
                                        result += String.fromCharCode(
                                            bytedata.shift()
                                        )
                                    }
                                    resultList[filename] = result
                                }
                            }
                            loadNext()
                        }
                        img.src = uri
                    } else {
                        resultList[uri] = result
                        loadNext()
                    }
                })
            } else {
                delegate(resultList)
            }
        }

        loadNext()
    }
}
