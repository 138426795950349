import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import CloseButton from 'components/Button/CloseButton';
import EndScreenButton from 'components/Button/EndScreenButton';

import './styles.scss';

type EndScreenProps = {
    onContinueClick: () => void;
    onStartOverClick: () => void;
}

const EndScreen: React.FC<EndScreenProps> = ({
    onContinueClick,
    onStartOverClick,
}) => {
    const { t } = useTranslation();

    return (
        <div className="end-screen-container">
            <div className="popup-modal">
                <CloseButton
                    stroke="#091339"
                    fill="#091339"
                    className="close-popup-modal"
                    onClick={onContinueClick}
                />
                <div className="choose-title">
                    <Trans>END_SCREEN_TITLE</Trans>
                </div>
                <div className="choose-container">
                    <EndScreenButton
                        label={t('END_SCREEN_CONTINUE')!}
                        onClick={onContinueClick}
                    />
                    <EndScreenButton
                        label={t('END_SCREEN_START_OVER')!}
                        onClick={onStartOverClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default EndScreen;
