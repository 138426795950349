import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const PointLeft: React.FC<IconProps> = ({
    className,
    height = 20,
    width = 20,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 17 6"
        width={width}
    >
        <path d="M15.1973 2.74951L2.86126 2.74949" stroke="white" strokeWidth="0.856667" />
        <circle cx="2.86145" cy="2.74939" r="2.74133" transform="rotate(-90 2.86145 2.74939)" fill="white" />
    </IconBase>
);

export default PointLeft;
