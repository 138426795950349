import React from 'react';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import appStore from 'app/store';
import ConfigLoader from 'components/ConfigLoader';
import NotificationProvider from 'providers/NotificationProvider';
import { ROOT_ID } from 'utils/params';

import App from './components/App';
import reportWebVitals from './reportWebVitals';

import 'assets/styles/_general.scss';

const root = ReactDOM.createRoot(
    document.getElementById(ROOT_ID) as HTMLElement,
);

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route path=":language" element={<App />} />
        </Route>,
    ),
);

root.render(
    <React.StrictMode>
        <Provider store={appStore}>
            <NotificationProvider>
                <ConfigLoader>
                    <RouterProvider router={router} />
                </ConfigLoader>
            </NotificationProvider>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
