import LayoutManager from './layout-manager'
import { LoadedResources } from './models'
import Program from './program'
import ResourceLoader from './resource-loader'
import Texture from './texture'

export default class GfxPipeline {
    DEFAULT_RENDER_WIDTH: number = 960
    DEFAULT_RENDER_HEIGHT: number = 540
    TARGET_FRAMERATE: number = 20

    engineVersion: string
    renderWidth: number
    renderHeight: number
    resourcePath: string
    layoutManager: LayoutManager
    canvas: HTMLCanvasElement
    image: Texture
    gl: WebGLRenderingContext
    dirty: boolean

    _activeProgram: Program
    _mainProgram: Program
    _uvDecodeProgram: Program
    _uvRelaxProgram: Program
    _uvDecodeTexture: Texture
    _uvRelaxTexture: Texture
    _mainUniforms: any
    _uvDecodeUniforms: any
    _uvRelaxUniforms: any
    _framebuffer: WebGLFramebuffer
    _renderTarget: any
    _blitAttributes: any
    _blitVertices: any
    _blitUv: any

    static generatorCache: { [ key: string ]: LoadedResources } = {}
    static textureSize: number = -1

    constructor() {
        this.engineVersion = window['engineVersion'] || 'v4'
        this.renderWidth = this.DEFAULT_RENDER_WIDTH
        this.renderHeight = this.DEFAULT_RENDER_HEIGHT
        this.resourcePath = ''
    }

    public setLayoutData(data: any): void {
        this.layoutManager = new LayoutManager(data)
        this.renderWidth = data.main.rect[2]
        this.renderHeight = data.main.rect[3]
    }

    public setupPipeline(
        readyDelegate: () => void,
        loopDelegate: () => void
    ): void {
        this.canvas = document.createElement('canvas')
        this.canvas.width = this.renderWidth
        this.canvas.height = this.renderHeight
        this.canvas.style.position = 'absolute'
        if (this.renderWidth > this.renderHeight) {
            // HORIZONTAL VIDEOS
            this.canvas.style.width = '100vw'
            this.canvas.style.height =
                (100 / this.renderWidth) * this.renderHeight + 'vw'
            this.canvas.style.top =
                'calc(50% - ' +
                ((100 / this.renderWidth) * this.renderHeight) / 2 +
                'vw)' // using 50% as 50vw somehow gives the wrong width on iPhone
            this.canvas.style.left = '0px'
        } else {
            // VERTICAL VIDEOS
            this.canvas.style.height = '100vh'
            this.canvas.style.width =
                (100 / this.renderHeight) * this.renderWidth + 'vh'
            this.canvas.style.right =
                'calc(50% - ' +
                ((100 / this.renderHeight) * this.renderWidth) / 2 +
                'vh)' // using 50% as 50vh somehow gives the wrong height on iPhone
            this.canvas.style.top = '0px'
        }

        document.body.appendChild(this.canvas)
        
        if (!!GfxPipeline.generatorCache[this.engineVersion]) {
            this.handleGeneratorLoaded(GfxPipeline.generatorCache[ this.engineVersion ], readyDelegate, loopDelegate)
        } else {
            ResourceLoader.loadResources(
                [ this.resourcePath + 'shader/vcgenerator.' + this.engineVersion ],
                (resources: LoadedResources) => {
                    GfxPipeline.generatorCache[ this.engineVersion ] = resources

                    this.handleGeneratorLoaded(resources, readyDelegate, loopDelegate)
                }
            )
        }
    }

    private handleGeneratorLoaded(resources: LoadedResources, readyDelegate: () => void, loopDelegate: () => void) {
        if (!this.canvas) {
            return
        }

        var gl: WebGLRenderingContext = (this.gl = this.canvas.getContext(
            'experimental-webgl',
            { preserveDrawingBuffer: true }
        ) as WebGLRenderingContext)
        var width: number
        var height: number
        
        
        this.layoutManager.setShaderTemplate(
            resources['shader/fragment.shader.' + this.engineVersion]
        )
        this._mainProgram = Program.createProgram(
            gl,
            resources['shader/vertex.shader.' + this.engineVersion],
            this.layoutManager.generateShaderCode()
        )
        this.image = new Texture(gl, width, height)

        if (GfxPipeline.textureSize === -1) {
            GfxPipeline.textureSize = this.getIdealTextureSize(this.image)
        }
        // console.log('Ideal texture size is: ' + this.textureSize)
        this._mainUniforms = {
            u_image: this.image.texture,
        }
        this.dirty = true

        var uniforms = Object.keys(this._mainProgram.uniforms)
        if (uniforms.indexOf('u_mouse') >= 0) {
            window.onmousemove = (evt) => {
                var x: number =
                    (evt.clientX / window.innerWidth - 0.5) * 2.0
                var y: number =
                    (evt.clientY / window.innerHeight - 0.5) * 1.0 + 0.4
                this._mainUniforms['u_mouse'] = [x, y]
                this.dirty = true
            }
            window.ondeviceorientation = (evt) => {
                if (evt.gamma && evt.beta) {
                    var x: number = Math.max(
                        -1.5,
                        Math.min(1.5, evt.gamma * 0.035)
                    )
                    var y: number = Math.max(
                        -1,
                        Math.min(2, evt.beta * 0.015) - 1.0
                    )
                    this._mainUniforms['u_mouse'] = [x, y]
                    this.dirty = true
                }
            }
        }

        // Prepare the uv decoding pipeline if an explicit uv texture is used in the main program.
        if (uniforms.indexOf('u_uv_image') >= 0) {
            // Use the first texture section to determine the source uv region.
            var uvRect: number[]
            var sections = this.layoutManager.getSections()
            for (var i: number = 0; i < sections.length; i++) {
                if (sections[i].type == 'uv' && sections[i].rect) {
                    uvRect = this.layoutManager.getNormalizedRect(
                        sections[i].rect
                    )
                    break
                }
            }
            if (uvRect) {
                this._uvDecodeProgram = Program.createProgram(
                    gl,
                    resources['shader/vertex.uvDecode'],
                    resources['shader/fragment.uvDecode']
                )
                this._uvDecodeTexture = new Texture(
                    gl,
                    this.renderWidth / 2,
                    this.renderHeight / 2
                )
                this._uvDecodeTexture.filterMode = 'NEAREST'
                this._uvDecodeUniforms = {
                    u_rect: [
                        uvRect[0],
                        1 - (uvRect[1] + uvRect[3]),
                        uvRect[2],
                        uvRect[3],
                    ],
                    u_image: this.image.texture,
                }
                this._uvRelaxProgram = Program.createProgram(
                    gl,
                    resources['shader/vertex.uvRelax'],
                    resources['shader/fragment.uvRelax']
                )
                this._uvRelaxTexture = new Texture(
                    gl,
                    this.renderWidth / 2,
                    this.renderHeight / 2
                )
                this._uvRelaxTexture.filterMode = 'NEAREST'
                this._uvRelaxUniforms = {
                    u_uv_image: this._uvDecodeTexture.texture,
                    u_uv_image_size: this.getTextureSizeUniform(
                        this._uvDecodeTexture
                    ),
                    u_direction: [0.0, 1.0],
                }
                this._mainUniforms['u_uv_image'] =
                    this._uvDecodeTexture.texture
                this._mainUniforms['u_uv_image_size'] =
                    this.getTextureSizeUniform(this._uvDecodeTexture)
            } else {
                console.error(
                    'No texture section found to provide sampling region.'
                )
            }
        }

        readyDelegate()
        gl.disable(gl.DEPTH_TEST)
        gl.disable(gl.BLEND)
        
        if (this._mainProgram.linked) {
            this.renderLoop(loopDelegate)
        }
    }

    /**
     * Get size uniform for the given texture.
     */
    public getTextureSizeUniform(texture: any): number[] {
        return [
            texture.width,
            texture.height,
            1 / texture.width,
            1 / texture.height,
        ]
    }

    public getIdealTextureSize(testTexture): number {
        // measure texture upload speed
        var testPixels: HTMLCanvasElement = document.createElement('canvas')
        var acceptable: boolean = true
        var size: number = 128
        var maxSize: number = this.gl.getParameter(this.gl.MAX_TEXTURE_SIZE)
        while (acceptable) {
            // get the next POT
            size *= 2
            // Don't use/test unsupported size.
            if (size > maxSize) {
                acceptable = false
                break
            }
            try {
                // make a blank sheet of pixels of that size
                var tries: number = 5
                var fast: boolean = false
                for (var i: number = 0; i < tries && !fast; i++) {
                    testPixels.width = size
                    testPixels.height = size
                    var time: number = new Date().getTime()
                    testTexture.drawTexture(testPixels)
                    time = new Date().getTime() - time
                    // want to get at least 20 fps, but if it failed somehow give it a chance to recover
                    if (time < 1000 / this.TARGET_FRAMERATE) fast = true
                }
                if (!fast) acceptable = false
            } catch (e) {
                // probably the texture upload size was too large
                acceptable = false
            }
        }
        if (navigator.userAgent.indexOf('iPhone;') != -1) size /= 2
        return size / 2
    }

    /**
     * Use the specified program for rendering.
     */
    public setActiveProgram(program: Program): void {
        if (this._activeProgram != program) {
            this.gl.useProgram(program.program)
            this._activeProgram = program
        }
    }

    /**
     * Sets the target for rendering.
     */
    public setRenderTarget(target, targetRect: number[]): void {
        if (target === void 0) {
            target = null
        }
        if (targetRect === void 0) {
            targetRect = null
        }
        var gl: WebGLRenderingContext = this.gl
        if (target) {
            if (!this._framebuffer) {
                this._framebuffer = gl.createFramebuffer()
            }
            if (!this._renderTarget) {
                gl.bindFramebuffer(gl.FRAMEBUFFER, this._framebuffer)
            }
            if (this._renderTarget != target) {
                gl.framebufferTexture2D(
                    gl.FRAMEBUFFER,
                    gl.COLOR_ATTACHMENT0,
                    gl.TEXTURE_2D,
                    target.texture,
                    0
                )
            }
            if (targetRect) {
                gl.viewport(
                    targetRect[0],
                    targetRect[1],
                    targetRect[2],
                    targetRect[3]
                )
            } else {
                gl.viewport(0, 0, target.width, target.height)
            }
        } else {
            if (this._renderTarget) {
                gl.bindFramebuffer(gl.FRAMEBUFFER, null)
            }
            if (targetRect) {
                gl.viewport(
                    targetRect[0],
                    targetRect[1],
                    targetRect[2],
                    targetRect[3]
                )
            } else {
                gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight)
            }
        }
        this._renderTarget = target
    }

    /**
     * Draws a fullscreen rectangle to the target texture.
     */
    public blit(
        target: any,
        program: Program,
        uniforms: any[],
        targetRect?: number[]
    ): void {
        if (targetRect === void 0) {
            targetRect = null
        }
        var gl: WebGLRenderingContext = this.gl
        if (!this._blitAttributes) {
            this._blitUv = gl.createBuffer()
            gl.bindBuffer(gl.ARRAY_BUFFER, this._blitUv)
            gl.bufferData(
                gl.ARRAY_BUFFER,
                new Float32Array([0, 0, 1, 0, 1, 1, 1, 1, 0, 1, 0, 0]),
                gl.STATIC_DRAW
            )
            this._blitVertices = gl.createBuffer()
            gl.bindBuffer(gl.ARRAY_BUFFER, this._blitVertices)
            gl.bufferData(
                gl.ARRAY_BUFFER,
                new Float32Array([-1, -1, 1, -1, 1, 1, 1, 1, -1, 1, -1, -1]),
                gl.STATIC_DRAW
            )
            this._blitAttributes = {
                attributes: {
                    a_position: { buffer: this._blitVertices, size: 2 },
                    a_texCoord: { buffer: this._blitUv, size: 2 },
                },
                numElements: 6,
            }
        }
        this.setRenderTarget(target, targetRect)
        this.setActiveProgram(program)
        program.setUniforms(uniforms)
        program.setAttributes(this._blitAttributes)
        gl.drawArrays(gl.TRIANGLES, 0, this._blitAttributes.numElements)
    }

    public dispose(): void {
        // Dispose of Programs.
        if (this._activeProgram != undefined) this._activeProgram.dispose()
        if (this._mainProgram != undefined) this._mainProgram.dispose()
        if (this._uvDecodeProgram != undefined) this._uvDecodeProgram.dispose()
        if (this._uvRelaxProgram != undefined) this._uvRelaxProgram.dispose()
        // Dispose of Textures.
        if (this._renderTarget != undefined) this._renderTarget.dispose()
        if (this._uvDecodeTexture != undefined) this._uvDecodeTexture.dispose()
        if (this._uvRelaxTexture != undefined) this._uvRelaxTexture.dispose()
        // Remove main Canvas element from the DOM.
        if (this.canvas) {
            this.canvas.parentElement.removeChild(this.canvas)
        }
        // Reset all local instances.
        this.canvas =
            this.image =
            this.layoutManager =
            this.gl =
            this._activeProgram =
            this._mainProgram =
            this._uvDecodeProgram =
            this._uvRelaxProgram =
            this._renderTarget =
            this._uvDecodeTexture =
            this._uvRelaxTexture =
            this._blitUv =
            this._blitVertices =
            this._blitAttributes =
            this._mainUniforms =
            this._uvDecodeUniforms =
            this._uvRelaxUniforms =
                undefined
    }

    public renderLoop(loopDelegate: () => void): void {
        var doLoop = (): void => {
            if (this.layoutManager == undefined) return
            loopDelegate()
            if (this.image.dirty || this.layoutManager.dirty || this.dirty) {
                if (this.layoutManager.dirty) {
                    this.layoutManager.updateMaterial(this._mainUniforms)
                }
                if (this.image.dirty) {
                    this._mainUniforms['u_image_size'] =
                        this.getTextureSizeUniform(this.image)
                    // Update derived textures.
                    if (this._uvDecodeProgram) {
                        this._uvDecodeUniforms['u_image_size'] =
                            this._mainUniforms['u_image_size']
                        this.blit(
                            this._uvDecodeTexture,
                            this._uvDecodeProgram,
                            this._uvDecodeUniforms
                        )
                        // Relax uvs to reduce jitter caused by quantization and compression.
                        for (var i: number = 0; i < 1; i++) {
                            this._uvRelaxUniforms['u_uv_image'] =
                                this._uvDecodeTexture.texture
                            this._uvRelaxUniforms['u_direction'] = [1.0, 0.0]
                            this.blit(
                                this._uvRelaxTexture,
                                this._uvRelaxProgram,
                                this._uvRelaxUniforms
                            )
                            this._uvRelaxUniforms['u_uv_image'] =
                                this._uvRelaxTexture.texture
                            this._uvRelaxUniforms['u_direction'] = [0.0, 1.0]
                            this.blit(
                                this._uvDecodeTexture,
                                this._uvRelaxProgram,
                                this._uvRelaxUniforms
                            )
                            this._uvRelaxUniforms['u_uv_image'] =
                                this._uvDecodeTexture.texture
                            this._uvRelaxUniforms['u_direction'] = [1.0, 1.0]
                            this.blit(
                                this._uvRelaxTexture,
                                this._uvRelaxProgram,
                                this._uvRelaxUniforms
                            )
                            this._uvRelaxUniforms['u_uv_image'] =
                                this._uvRelaxTexture.texture
                            this._uvRelaxUniforms['u_direction'] = [1.0, -1.0]
                            this.blit(
                                this._uvDecodeTexture,
                                this._uvRelaxProgram,
                                this._uvRelaxUniforms
                            )
                        }
                    }
                }
                this.blit(null, this._mainProgram, this._mainUniforms)
                this.layoutManager.dirty = false
                this.image.dirty = false
                this.dirty = false
            }
            window.requestAnimationFrame(doLoop)
        }
        doLoop()
    }
}
