import React from 'react';

import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import 'swiper/css';
import 'swiper/css/navigation';

import './styles.scss';
import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import ImageDownloadButton from 'components/Button/ImageDownloadButton';
import ColorScheme from 'components/ColorScheme';
import EndScreen from 'components/EndScreen';
import RoomSelectionTutorialOverlay from 'components/RoomSelectionTutorialOverlay';
import useViewport, { ViewportContext } from 'hooks/viewport';
import {
    getHasShownRoomSelectionTutorial,
    getIsEndScreen,
    getMainColorId,
} from 'selectors/app';

import RoomColorizerSwiper from './RoomColorizerSwiper/RoomColorizerSwiper';

interface RoomColorizerPageProps {
    className: string
}

const RoomColorizerPage: React.FC<RoomColorizerPageProps> = ({ className }) => {
    const dispatch = useAppDispatch();
    const viewport: ViewportContext = useViewport();

    const mainColorId: string = useAppSelector<string>(getMainColorId);
    const hasShownRoomSelectionTutorial: boolean = useAppSelector<boolean>(
        getHasShownRoomSelectionTutorial,
    );
    const isEndScreen: boolean = useAppSelector<boolean>(getIsEndScreen);

    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className="colorizer-app room-colorizer-page">
                {!hasShownRoomSelectionTutorial && (
                    <RoomSelectionTutorialOverlay />
                )}

                <div
                    className={clsx('col-container', {
                        desktop: viewport.orientation === 'desktop',
                    })}
                >
                    <div className="col left">
                        <div className="color-palette-subtitle mb-15">
                            <Trans>ROOM_SWIPE_LINKS</Trans>
                        </div>

                        <RoomColorizerSwiper />
                    </div>

                    <div className="col right">
                        <ImageDownloadButton
                            label={t('DOWNLOAD_BUTTON_SAVE_PHOTO_AND_COLORS')!}
                        />

                        <div className="color-select-title">
                            <Trans>ROOM_SUGGESTED_COLOR_COMBINATIONS</Trans>
                        </div>

                        <ColorScheme colorID={mainColorId} />
                    </div>
                </div>
            </div>

            {isEndScreen && (
                <EndScreen
                    onContinueClick={() => {
                        dispatch(appActions.setIsColorPalettePage(false));
                        dispatch(appActions.setIsEndScreen(false));
                    }}
                    onStartOverClick={() => {
                        dispatch(appActions.setIsColorPalettePage(true));
                        dispatch(appActions.setIsEndScreen(false));
                    }}
                />
            )}
        </div>
    );
};

export default RoomColorizerPage;
