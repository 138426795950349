import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const ArrowRightColorPalette: React.FC<IconProps> = ({
    className,
    height = 58,
    width = 58,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 58 58"
        width={width}
    >
        <g filter="url(#filter0_d_821_821)">
            <circle cx="29" cy="25" r="24" fill="black" fillOpacity="0.25" stroke="white" strokeWidth="2" />
            <g filter="url(#filter1_d_821_821)">
                <path d="M29.4286 13H20L33.619 25.5L20 38H29.4286L42 25.5L29.4286 13Z" fill="white" />
            </g>
        </g>
        <defs>
            <filter id="filter0_d_821_821" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_821_821" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_821_821" result="shape" />
            </filter>
            <filter id="filter1_d_821_821" x="16" y="13" width="30" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_821_821" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_821_821" result="shape" />
            </filter>
        </defs>
    </IconBase>
);

export default ArrowRightColorPalette;
