import React from 'react';

import './styles.scss';

import clsx from 'clsx';

import appActions from 'actions/app';
import { useAppDispatch } from 'app/hook';
import Button, { ButtonProps } from 'components/Button';
import ImageDownload from 'components/Icon/ImageDownload';

type ImageDownloadButtonProps = {
    label?: string;
}

const ImageDownloadButton: React.FC<ButtonProps & ImageDownloadButtonProps> = ({
    className,
    label,
}) => {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(appActions.setShouldDownloadImage(true));
    };

    return (
        <Button className={clsx('image-download-button', className)} onClick={handleClick}>
            <div className="container">
                <div className="icon-container">
                    <ImageDownload />
                </div>
                <div className="label">
                    {label}
                </div>
            </div>
        </Button>
    );
};

ImageDownloadButton.defaultProps = {
    label: undefined,
};

export default ImageDownloadButton;
