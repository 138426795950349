import type { RootState } from 'app/store';
import { ColorPaletteConfig } from 'types/config';
import { LanguageDescription } from 'utils/i18next';

export const selectFullConfig = (state: RootState) => state.config.fullConfig;

export const selectColorConfig = (colorID?: string) => (state: RootState) => {
    if (!colorID) {
        return undefined;
    }
    const color = selectFullConfig(state)?.Colors.find(
        (item) => item.ID === colorID,
    );
    return color;
};

export const selectColorFamilies = (state: RootState): ColorPaletteConfig[] =>
    selectFullConfig(state).ColorFamilies.map((colorFamily) => (
        {
            name: colorFamily.Family,
            colors: colorFamily.Colors.map((color) => selectColorConfig(color)(state)),
            columns: colorFamily.ColumnNumber,
            rgb: colorFamily.RGB,
        }
    ));

export const selectLanguages = (state: RootState): LanguageDescription[] =>
    state.config.languages;

export const selectClientName = (state: RootState): LanguageDescription[] =>
    state.config.languages;
