import { configureStore } from '@reduxjs/toolkit';

import appReducer from 'reducers/app';
import configReducer from 'reducers/config';
import errorReducer from 'reducers/error';
import parametersSettingsReducer from 'reducers/parametersSettings';

const store = configureStore({
    reducer: {
        app: appReducer,
        config: configReducer,
        error: errorReducer,
        parametersSettings: parametersSettingsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
