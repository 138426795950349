import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const ArrowLeft: React.FC<IconProps> = ({
    className,
    height = 17,
    opacity,
    width = 17,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 17 17"
        width={width}
    >
        <path
            d="M8.3933 0L9.1571 0.763794L2.0209 7.89999L16.3585 7.89999V8.89999L2.02224 8.89999L9.1571 16.0349L8.3933 16.7986L0 8.40534L0.364479 8.04086L0.35846 8.03484L8.3933 0Z"
            fill="#000000"
            fillOpacity={opacity}
            strokeOpacity="0"
            fillRule="evenodd"
            clipRule="evenodd"
        />
    </IconBase>
);

export default ArrowLeft;
