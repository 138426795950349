import React from 'react';

import './styles.scss';

import clsx from 'clsx';

import Button, { ButtonProps } from 'components/Button';
import ArrowLeft from 'components/Icon/ArrowLeft';

const BackButton: React.FC<ButtonProps> = ({
    className,
    onClick,
}) => (
    <Button className={clsx('back-button', className)} onClick={onClick}>
        <div className="icon-container">
            <ArrowLeft />
        </div>
    </Button>
);

export default BackButton;
