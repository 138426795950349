import { AxiosError } from 'axios';

import { CustomError } from 'types/error';

export enum ErrorType {
    AUTH = 'auth',
    NOT_FOUND = 'not-found',
    SERVER_ERROR = 'server-error',
    UNKNOWN = 'unknown',
}

export const getErrorFromAxios = (
    axiosError: AxiosError,
    customAuthText?: string,
): CustomError => {
    let title: string = '';
    let text: string = '';
    let type: ErrorType = ErrorType.UNKNOWN;

    if (!axiosError.response) {
        title = 'ERROR_TYPE_NETWORK';
        text = 'ERROR_GENERIC_NETWORK';
    } else {
        switch (axiosError.response.status) {
            case 401:
                title = 'Authentication error';
                text =
                    customAuthText ||
                    'The email or password provided is incorrect.';
                type = ErrorType.AUTH;
                break;

            case 404:
                title = 'Not found';
                text = 'The requested data was not found.';
                type = ErrorType.NOT_FOUND;
                break;

            case 500:
                title = 'Server error';
                text = 'Internal server error.';
                type = ErrorType.SERVER_ERROR;
                break;

            default:
                title = 'Unknown error';
                text = 'An unknown error has occurred.';
                type = ErrorType.UNKNOWN;
                break;
        }
    }

    return {
        title,
        text,
        type,
    };
};
