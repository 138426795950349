import type { RootState } from 'app/store';

// eslint-disable-next-line import/prefer-default-export
export const getDarkColorId = (state: RootState) => state.app.darkColorID;
export const getHasShownColorSelectionTutorial = (state: RootState) =>
    state.app.hasShownColorSelectionTutorial;
export const getHasShownRoomSelectionTutorial = (state: RootState) =>
    state.app.hasShownRoomSelectionTutorial;
export const getLanguage = (state: RootState) => state.app.language;
export const getLightColorId = (state: RootState) => state.app.lightColorID;
export const getMainColorId = (state: RootState) => state.app.mainColorID;
export const getShouldDownloadImage = (state: RootState) =>
    state.app.shouldDownloadImage;
export const getIsEndScreen = (state: RootState) => state.app.isEndScreen;
export const getIsColorPalettePage = (state: RootState) =>
    state.app.isColorPalettePage;
export const getSwiperActiveIndex = (state: RootState) =>
    state.app.swiperActiveIndex;
export const getWallSectionColors = (state: RootState) =>
    state.app.wallSectionColors;
