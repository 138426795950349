import React, { useEffect, useState } from 'react';

import './styles.scss';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';

import { useAppSelector } from 'app/hook';
import SearchIcon from 'components/Icon/SearchIcon';
import { selectFullConfig } from 'selectors/config';
import { Config, ColorConfig } from 'types/config';

type SearchComponentProps = {
    onSearchProductClick: (value: string) => void;
    placeholder: string;
};

const SearchComponent: React.FC<SearchComponentProps> = ({
    onSearchProductClick,
    placeholder,
}) => {
    const jsonConfigs: Config = useAppSelector<Config>(selectFullConfig);
    const { t } = useTranslation();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchList, setSearchList] = useState<ColorConfig[]>([]);

    const handleClick = (value: string) => {
        if (!onSearchProductClick) {
            return;
        }
        onSearchProductClick(value);
    };

    const debounceFunc = debounce(1000, (e: any) => {
        setSearchTerm(e.target.value);
    });

    useEffect(() => {
        const searchTermCaseInsensitive: string =
            searchTerm.toLocaleLowerCase();
        const list: ColorConfig[] = jsonConfigs?.Colors.map((color) => color);

        let filteredList: ColorConfig[] = [...list];

        if (searchTermCaseInsensitive.length) {
            filteredList = filteredList.filter(
                (product: ColorConfig): boolean => {
                    const colorName: string = t(product.ID) || product.Name;

                    return colorName
                        .toLowerCase()
                        .includes(searchTermCaseInsensitive);
                },
            );
        }
        setSearchList(filteredList);
    }, [searchTerm]);

    return (
        <>
            <div className="search-component-container">
                <SearchIcon />
                <div className="search-label">
                    <form className="search-form" action="">
                        <input
                            type="text"
                            id="search"
                            placeholder={placeholder}
                            onChange={debounceFunc}
                        />
                    </form>
                </div>
            </div>
            <div
                className={clsx('search-result-container', {
                    'd-none': !searchTerm,
                })}
            >
                <div className="search-result-list">
                    {searchList.map((product) => (
                        <div className="product-container" key={product.ID}>
                            <div
                                className="product-color"
                                style={{ backgroundColor: `#${product.RGB}` }}
                                onClick={() => {
                                    handleClick(product.ID);
                                }}
                                role="presentation"
                            />
                            <div
                                className="product"
                                onClick={() => {
                                    handleClick(product.ID);
                                }}
                                role="presentation"
                            >
                                {product.ID ? t(product.ID) : product.Name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SearchComponent;
