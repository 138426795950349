import React from 'react';

export type IconBaseProps = {
    children: React.ReactNode;
    className?: string;
    height?: number;
    onClick?: (event: React.MouseEvent) => void;
    viewBox?: string;
    width?: number;
};

const IconBase: React.FC<IconBaseProps> = ({
    children,
    className,
    height = 24,
    onClick,
    viewBox = '0 0 24 24',
    width = 24,
}) => (
    <svg
        className={className}
        height={height}
        onClick={onClick}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        width={width}
    >
        {children}
    </svg>
);

IconBase.defaultProps = {
    className: undefined,
    height: undefined,
    onClick: undefined,
    viewBox: undefined,
    width: undefined,
};

export default IconBase;
