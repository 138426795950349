import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const SearchIcon: React.FC<IconProps> = ({
    className,
    height = 19,
    width = 18,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 18 19"
        width={width}
    >
        <path
            d="M7.65 1.20635C8.92468 1.20518 10.1711 1.58211 11.2315 2.28943C12.2919 2.99676 13.1187 4.00272 13.6073 5.18004C14.096 6.35736 14.2244 7.65315 13.9764 8.90348C13.7285 10.1538 13.1152 11.3025 12.2143 12.2043C11.3134 13.106 10.1652 13.7203 8.91514 13.9694C7.66504 14.2185 6.36913 14.0912 5.19137 13.6037C4.0136 13.1162 3.00689 12.2903 2.29859 11.2305C1.5903 10.1707 1.21223 8.92468 1.21223 7.65C1.21996 5.94443 1.90052 4.31084 3.106 3.10426C4.31147 1.89768 5.94444 1.21563 7.65 1.20635ZM7.65 0C6.13697 0 4.65792 0.448664 3.39989 1.28926C2.14185 2.12985 1.16133 3.32462 0.582325 4.72247C0.00331473 6.12032 -0.148181 7.65848 0.146996 9.14244C0.442173 10.6264 1.17076 11.9895 2.24064 13.0594C3.31051 14.1292 4.67361 14.8578 6.15756 15.153C7.64151 15.4482 9.17967 15.2967 10.5775 14.7177C11.9754 14.1387 13.1701 13.1581 14.0107 11.9001C14.8513 10.6421 15.3 9.16302 15.3 7.65C15.3 5.62109 14.494 3.67528 13.0594 2.24063C11.6247 0.805979 9.6789 0 7.65 0Z"
            fill="#666666"
        />
        <path
            d="M17.8451 18.0655L12.7568 13L12 13.7726L17.0883 18.8381C17.1376 18.8891 17.1963 18.9297 17.2609 18.9575C17.3255 18.9853 17.3948 18.9997 17.4648 19C17.5349 19.0003 17.6043 18.9863 17.669 18.959C17.7338 18.9317 17.7928 18.8915 17.8425 18.8408C17.8922 18.7901 17.9317 18.7298 17.9587 18.6634C17.9857 18.597 17.9997 18.5257 18 18.4537C18.0002 18.3817 17.9867 18.3104 17.9601 18.2438C17.9336 18.1772 17.8945 18.1166 17.8451 18.0655Z"
            fill="#666666"
        />
    </IconBase>
);

export default SearchIcon;
