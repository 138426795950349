import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from 'types/app';
import { CustomError } from 'types/error';

const initialState: AppState = {
    areSlidelistZonesVisible: true,
    darkColorID: '',
    error: undefined,
    hasShownColorSelectionTutorial: false,
    hasShownRoomSelectionTutorial: false,
    isEndScreen: false,
    isColorPalettePage: true,
    isSlidelistFullscreen: false,
    language: '',
    lightColorID: '',
    mainColorID: '',
    mainMenuOpenState: false,
    wallSectionColors: ['', '', ''],
    shouldDownloadImage: false,
    swiperActiveIndex: 0,
};

export type WallSectionColorPayload = {
    colorRGB?: string;
    wallIndex?: number;
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setAreSlidelistZonesVisible: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.areSlidelistZonesVisible = action.payload;
        },
        setError: (state, action: PayloadAction<CustomError | undefined>) => {
            state.error = action.payload;
        },
        setHasShownColorSelectionTutorial: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.hasShownColorSelectionTutorial = !!action.payload;
        },
        setHasShownRoomSelectionTutorial: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.hasShownRoomSelectionTutorial = !!action.payload;
        },
        setIsSlidelistFullscreen: (state, action: PayloadAction<boolean>) => {
            state.isSlidelistFullscreen = action.payload;
        },
        setMainMenuOpenState: (state, action: PayloadAction<boolean>) => {
            state.mainMenuOpenState = !!action.payload;
        },
        setWallSectionColor: (
            state,
            action: PayloadAction<WallSectionColorPayload>,
        ) => {
            const newWallSectionColors: string[] = [...state.wallSectionColors];
            newWallSectionColors[action.payload.wallIndex || 0] =
                action.payload.colorRGB || '';
            state.wallSectionColors = newWallSectionColors;
        },
        setShouldDownloadImage: (state, action: PayloadAction<boolean>) => {
            state.shouldDownloadImage = !!action.payload;
        },
        setIsEndScreen: (state, action: PayloadAction<boolean>) => {
            state.isEndScreen = !!action.payload;
        },
        setIsColorPalettePage: (state, action: PayloadAction<boolean>) => {
            state.isColorPalettePage = !!action.payload;
        },
        setSwiperActiveIndex: (state, action: PayloadAction<number>) => {
            state.swiperActiveIndex = action.payload;
        },
        setMainColorID: (state, action: PayloadAction<string>) => {
            state.mainColorID = action.payload;
        },
        setLightColorID: (state, action: PayloadAction<string>) => {
            state.lightColorID = action.payload;
        },
        setDarkColorID: (state, action: PayloadAction<string>) => {
            state.darkColorID = action.payload;
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
    },
});

export default appSlice.reducer;
