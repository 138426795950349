import { useState, useEffect } from 'react';

export type PageOrientation = 'desktop' | 'portrait' | 'landscape';

export interface ViewportContext {
    orientation: PageOrientation;
    height: number;
    width: number;
}

function getOrientation(width: number, height: number): PageOrientation {
    let orientation: PageOrientation = 'desktop';

    if (navigator.maxTouchPoints) {
        orientation = height > width ? 'portrait' : 'landscape';
    }

    return orientation;
}

function useViewport(): ViewportContext {
    const [viewport, setViewport] = useState<ViewportContext>({
        orientation: getOrientation(window.innerWidth, window.innerHeight),
        height: window.innerHeight,
        width: window.innerWidth,
    });

    useEffect(() => {
        const onResize = () => {
            setViewport({
                orientation: getOrientation(
                    window.innerWidth,
                    window.innerHeight,
                ),
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return viewport;
}

export default useViewport;
