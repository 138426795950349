import GfxPipeline from './gfx-pipeline'
import { ImageColorizeConfiguration, LoadedResources } from './models/index'
import ResourceLoader from './resource-loader'

export default class Colorizer {
    resourcePath: string
    container: HTMLElement
    loader: HTMLImageElement
    image: any
    pipeline: any
    onImageLoadedCallback: Function
    onRenderCallback: Function

    constructor() {
        this.resourcePath = ''
        this.container = document.body
    }
    public setResourcePath(aSrc: string): void {
        this.resourcePath = aSrc || ''
    }
    public setLoaderElement(aLoader: HTMLImageElement): void {
        this.loader = aLoader
    }
    public setContainerElement(aContainer: HTMLElement): void {
        if (aContainer == undefined) return
        this.container = aContainer
        // Reparent elements if present.
        if (this.image != undefined) {
            this.container.appendChild(this.image)
        }
        if (this.pipeline != undefined && this.pipeline.canvas != undefined) {
            this.container.appendChild(this.pipeline.canvas)
        }
    }
    public loadConfig(aSrc: string): void {
        this.dispose()
        this.createLoaderElement()
        var url: string = aSrc || ''

        ResourceLoader.loadResources(
            [this.resourcePath + url],
            (resources: LoadedResources): void => {
                var data = JSON.parse(resources[url])
                this.image = document.createElement('img')
                this.container.appendChild(this.image)
                this.image.style.display = 'none'
                this.pipeline = new GfxPipeline()
                this.pipeline.resourcePath = this.resourcePath
                if (data.engine && data.engine != '') {
                    this.pipeline.engineVersion = data.engine
                }
                this.pipeline.setLayoutData(data)
                this.pipeline.setupPipeline(
                    () => {
                        // Load an image for each texture layer.
                        /*
                        var textureUrls: string[] = ["images/Peppers.png", "images/Mouse.png"];
                        var textureIndex: number = 0;
                        var sections = this.pipeline.layoutManager.getSections();
                        for (var i: number = 0; i < sections.length; i++) {
                            if (sections[i].type == "texture") {
                                var theTexture: Texture = new Texture(this.pipeline.gl, 4, 4);
                                theTexture.mipmap = true;
                                theTexture.wrap = true;
                                theTexture.anisotropy = 2;
                                theTexture.filterMode = FilterMode.TRILINEAR;
                                this.pipeline.layoutManager.setTexture(sections[i].id, theTexture)
        
                                var img: HTMLImageElement = document.createElement("img");
                                ((image, texture) => {
                                    image.onload = () => {
                                        texture.drawTexture(<any>image);
                                        this.pipeline.dirty = true;
                                    };
                                })(img, theTexture);
                                img.src = textureUrls[textureIndex++ % textureUrls.length];
                            }
                        }
                        */
                        // Reset canvas styling and reparent to container.
                        if (this.pipeline.canvas) {
                            this.pipeline.canvas.style.width =
                                this.pipeline.canvas.style.height =
                                this.pipeline.canvas.style.top =
                                this.pipeline.canvas.style.left =
                                    ''
                            this.container.appendChild(this.pipeline.canvas)
                        }
                        // Remove loader when image is loaded and force a render.
                        this.image.onload = () => {
                            this.loader.parentElement.removeChild(this.loader)
                            this.loader = undefined
                            this.image.onload = undefined
                            if (this.onImageLoadedCallback != undefined)
                                this.onImageLoadedCallback()
                            this.render()
                        }
                        // Add image source.
                        var theSrcExtention: string = data.src.split('.').pop()
                        if (
                            theSrcExtention === 'jpg' ||
                            theSrcExtention == 'png'
                        ) {
                            this.image.src = this.getSource(data.src)
                        }
                    },
                    () => {
                        // The draw loop. Basically only draw texture when image is dirty.
                        if (
                            this.pipeline == undefined ||
                            (this.pipeline && !this.pipeline.image.dirty)
                        )
                            return
                        this.pipeline.image.drawTexture(this.image)
                        this.pipeline.setRenderTarget(this.pipeline.image)
                    }
                )
            }
        )
    }

    public setConfig(aConfig: ImageColorizeConfiguration): void {
        this.image = document.createElement('img')
        this.container.appendChild(this.image)
        this.image.style.display = 'none'
        this.pipeline = new GfxPipeline()
        this.pipeline.resourcePath = this.resourcePath
        if (aConfig.engine && aConfig.engine != '') {
            this.pipeline.engineVersion = aConfig.engine
        }
        this.pipeline.setLayoutData(aConfig)
        this.pipeline.setupPipeline(
            () => {
                if (this.pipeline.canvas) {
                    this.pipeline.canvas.style.width =
                        this.pipeline.canvas.style.height =
                        this.pipeline.canvas.style.top =
                        this.pipeline.canvas.style.left =
                            ''
                    this.container.appendChild(this.pipeline.canvas)
                }
                // Remove loader when image is loaded and force a render.
                this.image.onload = () => {
                    this.loader.parentElement.removeChild(this.loader)
                    this.loader = undefined
                    this.image.onload = undefined
                    if (this.onImageLoadedCallback != undefined)
                        this.onImageLoadedCallback()
                    this.render()
                }
                // Add image source.
                var theSrcExtention: string = aConfig.src.split('.').pop()
                if (theSrcExtention === 'jpg' || theSrcExtention == 'png') {
                    this.image.src = this.getSource(aConfig.src)
                }
            },
            () => {
                // The draw loop. Basically only draw texture when image is dirty.
                if (
                    this.pipeline == undefined ||
                    (this.pipeline && !this.pipeline.image.dirty)
                )
                    return
                this.pipeline.image.drawTexture(this.image)
                this.pipeline.setRenderTarget(this.pipeline.image)
            }
        )
    }

    public render(): void {
        if (this.pipeline && this.pipeline.image) {
            this.pipeline.image.dirty = true
            if (this.onRenderCallback != undefined) this.onRenderCallback()
        }
    }
    public getSource(src: any[] | string): string {
        if (typeof src == 'string') {
            return this.resourcePath + this.getFormat(src)
        }
        var ret: string = ''
        // var size: number = 0
        for (var i: number = 0; i < src.length; i++) {
            if (src[i].size <= GfxPipeline.textureSize) {
                ret = src[i].src
                // size = src[i].size
            }
        }
        if (ret == '') {
            ret = src[0].src
            // size = src[0].size
        }
        return this.resourcePath + this.getFormat(ret)
    }
    public getFormat(src: string): string {
        var format: string = 'mp4'
        var canPlayWebM: boolean = false
        var testVideo: HTMLVideoElement = document.createElement('video')
        if (
            testVideo['canPlayType'] &&
            testVideo['canPlayType']('video/webm').replace(/no/, '')
        ) {
            canPlayWebM = true
        }
        if (navigator.userAgent.match('(Chrome|Firefox)') && canPlayWebM) {
            format = 'webm'
        }
        return src.split('%{format}').join(format)
    }
    public dispose(): void {
        if (this.pipeline) this.pipeline.dispose()
        this.pipeline = undefined
        // Remove the image element from the DOM.
        if (this.image) {
            this.image.parentElement.removeChild(this.image)
            this.image = undefined
        }
        // Remove the loader element from the DOM if present.
        if (this.loader) {
            this.loader.parentElement.removeChild(this.loader)
            this.loader = undefined
        }
    }
    public createLoaderElement(): void {
        if (this.loader == undefined) {
            this.loader = document.createElement('img')
            this.loader.id = 'colorize-loader'
            this.loader.src = this.resourcePath + 'images/loader.gif'
            this.loader.style.width = '50px'
            this.loader.style.height = '50px'
            this.loader.style.position = 'absolute'
            this.loader.style.top = 'calc(50% - 25px)'
            this.loader.style.left = 'calc(50% - 25px)'
        }
        this.container.appendChild(this.loader)
    }
    /**
     * Decodes a frame index from the specified color components, in range [0, fps), or -1 if the index is invalid (black).
     */
    public decodeTimestamp(r: number, g: number, b: number): {} {
        return (
            ((r + 32) >> 6) +
            (((g + 32) >> 6) << 2) +
            (((b + 32) >> 6) << 4) -
            1
        )
    }
}
