import React, { useEffect, useRef, useState } from 'react';

import ImageColorizer from 'image_colorizer';
import { useTranslation } from 'react-i18next';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import styleVariables from 'assets/styles/_variables.module.scss';
import {
    getDarkColorId,
    getLightColorId,
    getMainColorId,
    getShouldDownloadImage,
    getSwiperActiveIndex,
    getWallSectionColors,
} from 'selectors/app';
import './styles.scss';
import { selectColorConfig, selectLanguages } from 'selectors/config';
import { ColorConfig } from 'types/config';
import i18n, { LanguageDescription } from 'utils/i18next';

interface ImageColorizeComponentProps {
    config: string;
    index?: number;
    shouldGenerateDownloadableImage?: boolean;
    wall1Color?: string;
    wall2Color?: string;
    wall3Color?: string;
}

const ImageColorizeComponent: React.FC<ImageColorizeComponentProps> = ({
    config,
    index,
    shouldGenerateDownloadableImage,
    wall1Color,
    wall2Color,
    wall3Color,
}) => {
    const dispatch = useAppDispatch();

    const [colorizer, setColorizer] = useState<
        ImageColorizer.Colorizer | undefined
    >(undefined);
    const [configName, setConfigName] = useState<string>();

    const imageContainer = useRef<HTMLDivElement>(null);

    const allLanguages: LanguageDescription[] = useAppSelector(selectLanguages);
    const shouldDownloadImage: boolean = useAppSelector<boolean>(
        getShouldDownloadImage,
    );
    const swiperActiveIndex: number =
        useAppSelector<number>(getSwiperActiveIndex);
    const wallSectionColor: string[] =
        useAppSelector<string[]>(getWallSectionColors);

    const setPropColors = () => {
        if (wall1Color) {
            colorizer?.pipeline.layoutManager.setColor('area1', wall1Color);
        }
        if (wall2Color) {
            colorizer?.pipeline.layoutManager.setColor('area2', wall2Color);
        }
        if (wall3Color) {
            colorizer?.pipeline.layoutManager.setColor('area3', wall3Color);
        }
    };

    const mainColorID: string = useAppSelector<string>(getMainColorId);

    const lightColorID: string = useAppSelector<string>(getLightColorId);

    const darkColorID: string = useAppSelector<string>(getDarkColorId);

    const mainColor: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
    >(selectColorConfig(mainColorID));

    const lightColor: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
    >(selectColorConfig(lightColorID));

    const darkColor: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
    >(selectColorConfig(darkColorID));

    const { t } = useTranslation();
    const imageTextLine1: string = t('IMAGE_COLORIZER_DESCRIPTION_TEXT_1');
    const imageTextLine2: string = t('IMAGE_COLORIZER_DESCRIPTION_TEXT_2');
    const imageTextLine3: string = t('IMAGE_COLORIZER_DESCRIPTION_TEXT_3');
    const imageTextLine4: string = t('IMAGE_COLORIZER_DESCRIPTION_TEXT_4');

    const getFlexaIcon = (): Promise<HTMLImageElement> =>
        new Promise(
            (
                resolve: (img: HTMLImageElement) => void,
                reject: (error: any) => void,
            ) => {
                const flexaIcon = document.createElement('img');
                flexaIcon.src = `/images/header/${configName}`;
                flexaIcon.onload = () => {
                    resolve(flexaIcon);
                };
                flexaIcon.onerror = (error: string | Event) => {
                    reject(error.toString());
                };
            },
        );

    const getAkzoIcon = (): Promise<HTMLImageElement> =>
        new Promise(
            (
                resolve: (img: HTMLImageElement) => void,
                reject: (error: any) => void,
            ) => {
                const akzoIcon = document.createElement('img');
                akzoIcon.src = '/images/header/akzo.png';
                akzoIcon.onload = () => {
                    resolve(akzoIcon);
                };
                akzoIcon.onerror = (error: string | Event) => {
                    reject(error.toString());
                };
            },
        );

    useEffect(() => {
        const currentLanguage: string = i18n.getCurrentLanguage();

        const languageDescription: LanguageDescription | undefined =
            allLanguages.find(
                (language: LanguageDescription): boolean =>
                    language.code === currentLanguage,
            );

        if (languageDescription) {
            setConfigName(languageDescription.logo);
        }
    }, [allLanguages]);

    useEffect(() => {
        setPropColors();

        if (!imageContainer.current) {
            return;
        }
        if (index !== swiperActiveIndex) {
            return;
        }
        const canvas = imageContainer.current.querySelector('canvas');
        const div = document.getElementById('full-screen-room');
        const fullScreenCanvas = document.getElementById('full-screen-canvas');
        if (fullScreenCanvas) {
            fullScreenCanvas.remove();
        }

        const destCanvas = document.createElement('canvas');
        destCanvas.width = 1024;
        destCanvas.height = 768;
        destCanvas.id = 'full-screen-canvas';
        div?.appendChild(destCanvas);
        if (!canvas) {
            return;
        }
        const imageData = canvas?.toDataURL('image/png');
        const ctx = destCanvas?.getContext('2d');
        if (!ctx) {
            return;
        }

        const destImage = new Image();
        destImage.onload = () => {
            ctx.drawImage(
                canvas,
                0,
                0,
                canvas.width,
                canvas.height,
                0,
                0,
                1024,
                768,
            );
        };
        destImage.src = imageData;
    }, [wall1Color, wall2Color, wall3Color]);

    useEffect(() => {
        if (
            !shouldDownloadImage ||
            !imageContainer.current ||
            shouldGenerateDownloadableImage === false
        ) {
            return;
        }
        if (index !== swiperActiveIndex) {
            return;
        }

        const generateImage = async () => {
            const getTextColor = (hex: string | undefined) => {
                if (hex === undefined) return '#000000';
                const red = parseInt(hex.substring(0, 2), 16);
                const green = parseInt(hex.substring(2, 4), 16);
                const blue = parseInt(hex.substring(4, 6), 16);
                const colorValue = Math.floor(
                    Math.sqrt(
                        0.2999 * red ** 2 +
                            0.587 * green ** 2 +
                            0.114 * blue ** 2,
                    ),
                );
                return colorValue < 135 ? '#FFFFFF' : '#000000';
            };

            const canvas = imageContainer?.current?.querySelector('canvas');
            if (canvas == null) {
                return;
            }

            const mainCanvas = document.createElement('canvas');
            mainCanvas.id = 'download-image-canvas';
            mainCanvas.height = 768;
            mainCanvas.width = 1503;
            const ctx = mainCanvas.getContext('2d');

            if (ctx == null) {
                return;
            }

            // draw a rounded rectangle with background
            const roundRect = (
                info: any,
                radius = { tr: 4, br: 4, bl: 4, tl: 4 },
                color = '#ffffff',
            ) => {
                const { x, y, w, h } = info;
                const r = x + w;
                const b = y + h;
                ctx.beginPath();
                ctx.fillStyle = color;
                ctx.moveTo(x + radius.tl, y);
                ctx.lineTo(r - radius.tr, y);
                ctx.quadraticCurveTo(r, y, r, y + radius.tr);
                ctx.lineTo(r, y + h - radius.br);
                ctx.quadraticCurveTo(r, b, r - radius.br, b);
                ctx.lineTo(x + radius.bl, b);
                ctx.quadraticCurveTo(x, b, x, b - radius.bl);
                ctx.lineTo(x, y + radius.tl);
                ctx.quadraticCurveTo(x, y, x + radius.tl, y);
                ctx.fill();
                ctx.closePath();
            };

            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, 479, 768);

            ctx.drawImage(
                canvas,
                0,
                0,
                canvas.width,
                canvas.height,
                479,
                0,
                1024,
                768,
            );

            // Flexa icon
            const flexaIcon = await getFlexaIcon();

            if (flexaIcon == null) {
                return;
            }
            ctx.drawImage(flexaIcon, 25, 25, 83, 83);

            // "Flexa Creations" text
            ctx.font = '700 36px Open Sans';
            ctx.fillStyle = '#000000';
            ctx.fillText(t('PRODUCT_NAME'), 131, 78);

            // Description text
            ctx.font = '400 24px Open Sans';
            ctx.fillStyle = '#000000';
            ctx.fillText(imageTextLine1, 30, 135);
            ctx.fillText(imageTextLine2, 30, 167);
            ctx.fillText(imageTextLine3, 30, 199);
            ctx.fillText(imageTextLine4, 30, 231);

            // rectangles positions, dimensions and corner radius
            const lightColorRectInfo = { x: 30, y: 256, w: 419, h: 120 };
            const mainColorRectInfo = { x: 30, y: 406, w: 419, h: 120 };
            const darkColorRectInfo = { x: 30, y: 556, w: 419, h: 120 };
            const colorRectRadius = { tr: 50, br: 0, bl: 50, tl: 0 };

            const fontSizeOptimizer = (colorName: string) => {
                ctx.font = '400 22px Open Sans';
                const text = ctx.measureText(colorName);
                if (text.width > 310) {
                    ctx.font = '400 19px Open Sans';
                }
                return ctx.font;
            };

            if (
                wall1Color == null &&
                wall2Color == null &&
                wall3Color == null
            ) {
                return;
            }
            if (wall1Color === wall2Color && wall1Color === wall3Color) {
                roundRect(
                    lightColorRectInfo,
                    colorRectRadius,
                    `#${wall2Color}`,
                );
                ctx.fillStyle = getTextColor(`${wall2Color}`);
                if (mainColor) {
                    const colorName: string = mainColor.ID
                        ? t(mainColor.ID)
                        : mainColor.Name;
                    ctx.font = fontSizeOptimizer(colorName);
                    ctx.textAlign = 'center';
                    ctx.fillText(colorName, 240, 325);
                }
            } else {
                roundRect(
                    lightColorRectInfo,
                    colorRectRadius,
                    `#${wall1Color}`,
                );
                ctx.fillStyle = getTextColor(`${wall1Color}`);
                if (lightColor) {
                    const colorName: string = lightColor.ID
                        ? t(lightColor.ID)
                        : lightColor.Name;
                    ctx.font = fontSizeOptimizer(colorName);
                    ctx.textAlign = 'center';
                    ctx.fillText(colorName, 240, 325);
                }

                roundRect(mainColorRectInfo, colorRectRadius, `#${wall2Color}`);
                ctx.fillStyle = getTextColor(`${wall2Color}`);
                if (mainColor) {
                    const colorName: string = mainColor.ID
                        ? t(mainColor.ID)
                        : mainColor.Name;
                    ctx.font = fontSizeOptimizer(colorName);
                    ctx.textAlign = 'center';
                    ctx.fillText(colorName, 240, 470);
                }

                roundRect(darkColorRectInfo, colorRectRadius, `#${wall3Color}`);
                ctx.fillStyle = getTextColor(`${wall3Color}`);
                if (darkColor) {
                    const colorName: string = darkColor.ID
                        ? t(darkColor.ID)
                        : darkColor.Name;
                    ctx.font = fontSizeOptimizer(colorName);
                    ctx.textAlign = 'center';
                    ctx.fillText(colorName, 240, 620);
                }
            }

            // Akzo icon
            const akzoIcon = await getAkzoIcon();

            if (akzoIcon == null) {
                return;
            }
            ctx.drawImage(akzoIcon, 218, 717, 66, 10);

            const filename: string = `${
                mainColor?.Name || 'colorized-room'
            }.jpg`;

            const webSharePlatforms = ['iPhone', 'iPod', 'iPad'];

            /* download function with web share api for camera roll */
            const imageDownload = async (): Promise<void> => {
                mainCanvas.toBlob((image: Blob | null) => {
                    if (!image) {
                        return;
                    }
                    const file = new File([image], filename, {
                        type: 'image/jpeg',
                    });
                    if (
                        !navigator.canShare ||
                        !navigator.canShare({ files: [file] })
                    ) {
                        return;
                    }
                    navigator
                        .share({
                            files: [file],
                        })
                        .catch((err) => {
                            alert((err as Error).message);
                        })
                        .then(() => {
                            dispatch(appActions.setIsEndScreen(true));
                        });
                }, 'image/jpeg');
            };

            if (webSharePlatforms.includes(navigator.platform)) {
                imageDownload();
                dispatch(appActions.setShouldDownloadImage(false));
                return;
            }
            const url = mainCanvas.toDataURL('image/jpeg');
            const link = document.createElement('a');
            link.download = filename;
            link.href = url;
            link.click();
            dispatch(appActions.setIsEndScreen(true));
            dispatch(appActions.setShouldDownloadImage(false));
        };

        generateImage();
    }, [index, shouldDownloadImage]);

    useEffect(() => {
        const imageColorizerKey: keyof Window =
            ImageColorizer.ImageColorizerKey as keyof Window;
        const imageColorizer: ImageColorizer.Colorizer = new window[
            imageColorizerKey
        ].Colorizer();

        setColorizer(imageColorizer);
    }, []);

    const onColorizerRenderCallback = () => {
        const canvas = imageContainer?.current?.querySelector('canvas');
        if (canvas == null) {
            return;
        }
        canvas.height =
            canvas.width / parseFloat(styleVariables.imageRatio) || 1.5;
        colorizer?.pipeline.layoutManager.setColor(
            'area2',
            wallSectionColor[1],
        );
    };

    useEffect(() => {
        if (!colorizer || !imageContainer.current) {
            return;
        }
        colorizer.onRenderCallback = onColorizerRenderCallback;
        colorizer.setResourcePath('/resources/colorize/');
        colorizer.setContainerElement(imageContainer.current);
        colorizer.loadConfig(config);
    }, [colorizer, config, imageContainer]);

    return <div className="colorizer-image-container" ref={imageContainer} />;
};

ImageColorizeComponent.defaultProps = {
    index: undefined,
    shouldGenerateDownloadableImage: undefined,
    wall1Color: undefined,
    wall2Color: undefined,
    wall3Color: undefined,
};

export default ImageColorizeComponent;
