import React from 'react';

import clsx from 'clsx';

import Button, { ButtonProps } from 'components/Button';
import ArrowRightColorPalette from 'components/Icon/ArrowRightColorPalette';
import './styles.scss';

const ArrowRightColorPaletteButton: React.FC<ButtonProps> = ({
    className,
    onClick,
}) => (
    <Button className={clsx('arrow-right-color-palette-button', className)} onClick={onClick}>
        <div className="icon-container">
            <ArrowRightColorPalette />
        </div>
    </Button>
);

export default ArrowRightColorPaletteButton;
