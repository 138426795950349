import React from 'react';

import { useTranslation } from 'react-i18next';

import appActions from 'actions/app';
import { useAppDispatch } from 'app/hook';
import { ColorConfig } from 'types/config';

import './styles.scss';

type ColorizableColorWrapperProps = {
    colors: (ColorConfig | undefined)[];
    displayLayout: string;
}

const ColorFamilyWrapper: React.FC<ColorizableColorWrapperProps> = ({
    colors,
    displayLayout,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const compareColorPaletteItems = (
        a: ColorConfig | undefined,
        b: ColorConfig | undefined,
    ) => {
        const firstItemPositionValue: number =
            parseInt(a?.RowNr || '0', 10) * 100 +
            parseInt(a?.ColumnNr || '0', 10);
        const secondItemPositionValue: number =
            parseInt(b?.RowNr || '0', 10) * 100 +
            parseInt(b?.ColumnNr || '0', 10);

        if (firstItemPositionValue < secondItemPositionValue) return -1;
        if (firstItemPositionValue > secondItemPositionValue) return 1;

        return 0;
    };

    const getTextColor = (hex: string | undefined) => {
        if (hex === undefined) return '#000000';
        const red = parseInt(hex.substring(0, 2), 16);
        const green = parseInt(hex.substring(2, 4), 16);
        const blue = parseInt(hex.substring(4, 6), 16);
        const colorValue = Math.floor(
            Math.sqrt(
                0.2999 * red ** 2 + 0.587 * green ** 2 + 0.114 * blue ** 2,
            ),
        );
        return colorValue < 135 ? '#FFFFFF' : '#000000';
    };

    const handleClick = (colorID?: string) => {
        dispatch(appActions.setMainColorID(colorID || ''));
        const color: ColorConfig | undefined =
            colors?.find((colorItem) => colorItem?.ID === colorID);
        if (!color) {
            return;
        }
        dispatch(appActions.setIsColorPalettePage(false));
        dispatch(
            appActions.setWallSectionColor({ colorRGB: color.RGB, wallIndex: 0 }),
        );
        dispatch(
            appActions.setWallSectionColor({ colorRGB: color.RGB, wallIndex: 1 }),
        );
        dispatch(
            appActions.setWallSectionColor({ colorRGB: color.RGB, wallIndex: 2 }),
        );
    };

    return (
        <div className="colors-container">
            {colors?.sort(compareColorPaletteItems).map((color) => (
                <React.Fragment
                    key={color?.ID}
                >
                    <div className={`colorizable-color-spacing ${displayLayout}`}>
                        <div
                            className="colorizable-color"
                            onClick={() => {
                                handleClick(color?.ID);
                            }}
                            role="presentation"
                            style={{
                                backgroundColor: `#${color?.RGB}`,
                            }}
                        >
                            <div className="color-name-container">
                                <p
                                    style={{
                                        color:
                                        getTextColor(
                                            `${color?.RGB}`,
                                        ),
                                    }}
                                >
                                    {color?.ID ? t(color?.ID) : color?.Name}
                                </p>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
};

export default ColorFamilyWrapper;
