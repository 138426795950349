import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import ArrowLeftColorPaletteButton from 'components/Button/ArrowLeftColorPaletteButton';
import ArrowRightColorPaletteButton from 'components/Button/ArrowRightColorPaletteButton';
import ColorFamilyWrapper from 'components/ColorFamilyWrapper';
import ColorSelectionTutorialOverlay from 'components/ColorSelectionTutorialOverlay';
import SearchComponent from 'components/SearchComponent';
import useViewport, { ViewportContext } from 'hooks/viewport';
import {
    getHasShownColorSelectionTutorial,
    getIsColorPalettePage,
    getLanguage,
} from 'selectors/app';
import { selectColorConfig, selectColorFamilies } from 'selectors/config';
import './styles.scss';
import { ColorConfig, ColorPaletteConfig } from 'types/config';

const ColorPalettePage: React.FC = () => {
    const dispatch = useAppDispatch();
    const viewport: ViewportContext = useViewport();

    const [selectedColorId, setSelectedColorId] = useState<string>();
    const [maxColumnNumber, setMaxColumnNumber] = useState<number>(0);
    const [activeColorPaletteIndex, setActiveColorPaletteIndex] =
        useState<number>(0);
    const [swiperInstance, setSwiperInstance] = useState<
        SwiperClass | undefined
    >(undefined);
    const [areAllSlidesVisible, setAreAllSlidesVisible] =
        useState<boolean>(false);

    const isColorPalettePage: boolean = useAppSelector<boolean>(
        getIsColorPalettePage,
    );
    const language = useAppSelector<string>(getLanguage);
    const selectedColor: ColorConfig | undefined = useAppSelector<
        ColorConfig | undefined
    >(selectColorConfig(selectedColorId));

    const colorFamily: ColorPaletteConfig[] =
        useAppSelector<ColorPaletteConfig[]>(selectColorFamilies);

    const hasShownColorSelectionTutorial: boolean = useAppSelector<boolean>(
        getHasShownColorSelectionTutorial,
    );

    const { t } = useTranslation();

    const handlePage = () => {
        const newColorPalette: boolean = !isColorPalettePage;
        dispatch(appActions.setIsColorPalettePage(newColorPalette));
        if (newColorPalette) {
            dispatch(
                appActions.setWallSectionColor({ colorRGB: '', wallIndex: 0 }),
            );
            dispatch(
                appActions.setWallSectionColor({ colorRGB: '', wallIndex: 1 }),
            );
            dispatch(
                appActions.setWallSectionColor({ colorRGB: '', wallIndex: 2 }),
            );
        }
    };

    useEffect(() => {
        if (!swiperInstance?.slides || !viewport?.width) return;

        const totalSlideWidth: number = swiperInstance.slides.map(
            (slide) => (slide as HTMLDivElement).offsetWidth,
        ).reduce((previousValue: number, currentValue: number) => previousValue + currentValue, 0);

        setAreAllSlidesVisible(totalSlideWidth <= viewport.width);
    }, [swiperInstance?.slides, viewport]);

    useEffect(() => {
        if (!selectedColorId) {
            return;
        }

        dispatch(appActions.setMainColorID(selectedColorId));
        dispatch(appActions.setLightColorID(selectedColorId));
        dispatch(appActions.setDarkColorID(selectedColorId));

        dispatch(
            appActions.setWallSectionColor({
                colorRGB: selectedColor?.RGB,
                wallIndex: 0,
            }),
        );
        dispatch(
            appActions.setWallSectionColor({
                colorRGB: selectedColor?.RGB,
                wallIndex: 1,
            }),
        );
        dispatch(
            appActions.setWallSectionColor({
                colorRGB: selectedColor?.RGB,
                wallIndex: 2,
            }),
        );

        handlePage();
    }, [selectedColorId]);

    const highestColumnNumber = (palette: ColorPaletteConfig): number => {
        let actualValue: number = 0;

        for (let i: number = 0; i < palette.colors.length; i += 1) {
            let value: string = '0';

            if (palette?.colors[i]?.ColumnNr) {
                value = palette?.colors[i]?.ColumnNr as string;
            }
            if (actualValue < parseInt(value, 10)) {
                actualValue = parseInt(value, 10);
            }
        }

        if (maxColumnNumber < actualValue) {
            setMaxColumnNumber(actualValue);
        }
        return actualValue;
    };

    const handleColorPaletteSlideChange = (swiper: SwiperClass) => {
        setActiveColorPaletteIndex(swiper.activeIndex);
    };

    const handleArrowLeftClick = () => {
        if (activeColorPaletteIndex === 0 || !swiperInstance) {
            return;
        }
        swiperInstance.slidePrev();
    };

    const handleArrowRightClick = () => {
        if (activeColorPaletteIndex === colorFamily.length || !swiperInstance) {
            return;
        }
        swiperInstance.slideNext();
    };

    return (
        <>
            <SearchComponent
                onSearchProductClick={(value: string) =>
                    setSelectedColorId(value || '')
                }
                placeholder={t('SEARCH_TYPE_THE_NAME_OF_THE_COLOR')}
            />
            <div className="colorizer-app color-palette-page">
                {!!language && !hasShownColorSelectionTutorial && (
                    <ColorSelectionTutorialOverlay />
                )}
                <div
                    className={clsx('color-palette-container', {
                        desktop: viewport.orientation === 'desktop',
                    })}
                >
                    {viewport.orientation !== 'desktop' && (
                        <div className="color-palette-subtitle">
                            <Trans>COLOR_PALETTE_SWIPE_LINKS</Trans>
                        </div>
                    )}
                    <div className="colorizable-colors-container">
                        <div className="colorizable-colors">
                            <div className="colorizable-color-container">
                                <ArrowLeftColorPaletteButton
                                    className={clsx({
                                        hide: activeColorPaletteIndex === 0,
                                    })}
                                    onClick={handleArrowLeftClick}
                                />
                                <ArrowRightColorPaletteButton
                                    className={clsx({
                                        hide: swiperInstance?.isEnd || areAllSlidesVisible,
                                    })}
                                    onClick={handleArrowRightClick}
                                />
                                <Swiper
                                    onSlideChange={
                                        handleColorPaletteSlideChange
                                    }
                                    onSwiper={(swiper: SwiperClass) => {
                                        setSwiperInstance(swiper);
                                    }}
                                    className="color-palette-swiper"
                                    breakpoints={{
                                        670: {
                                            slidesPerView: 2,
                                        },
                                        1000: {
                                            slidesPerView: 3,
                                        },
                                        1500: {
                                            width: 1500,
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {colorFamily?.map((palette) => (
                                        <SwiperSlide
                                            className="palette-container"
                                            key={palette.name}
                                        >
                                            <div className="colorizable-area-title-container">
                                                <div className="colorizable-area-title">
                                                    {palette.name}
                                                </div>
                                            </div>
                                            <ColorFamilyWrapper
                                                colors={palette.colors}
                                                displayLayout={
                                                    // eslint-disable-next-line no-nested-ternary
                                                    highestColumnNumber(
                                                        palette,
                                                    ) === 4
                                                        ? 'four-columns'
                                                        : maxColumnNumber === 4
                                                            ? 'three-columns-resized'
                                                            : ''
                                                }
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ColorPalettePage;
