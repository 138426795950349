import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const PointUp: React.FC<IconProps> = ({
    className,
    height = 20,
    width = 20,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 8 17"
        width={width}
    >
        <path d="M3.15723 0L3.1572 12.6027" stroke="white" strokeWidth="0.856667" />
        <ellipse cx="3.1571" cy="12.6022" rx="2.74133" ry="2.8006" transform="rotate(-180 3.1571 12.6022)" fill="white" />
    </IconBase>
);

export default PointUp;
