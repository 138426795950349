import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Trans } from 'react-i18next';

import { useAppSelector } from 'app/hook';
import { selectLanguages } from 'selectors/config';
import i18n, { LanguageDescription } from 'utils/i18next';
import './styles.scss';

interface HeaderProps {
    classname?: string;
}

const Header: React.FC<HeaderProps> = ({
    classname,
}) => {
    const allLanguages: LanguageDescription[] = useAppSelector(selectLanguages);
    const [logo, setLogo] = useState<string>('');

    useEffect(() => {
        const currentLanguage: string = i18n.getCurrentLanguage();

        const languageDescription: LanguageDescription | undefined =
            allLanguages.find(
                (language: LanguageDescription): boolean =>
                    language.code === currentLanguage,
            );

        if (languageDescription) {
            setLogo(languageDescription.logo);
        }
    }, [allLanguages]);

    return (
        <div className={clsx('header-container', classname)}>
            <div
                className="icon-container logo-icon"
                style={{
                    backgroundImage: `url(/images/header/${logo})`,
                }}
            />
            <div className="header-title">
                <Trans>PRODUCT_NAME</Trans>
            </div>
            <div className="icon-container akzo-icon" />
        </div>
    );
};

Header.defaultProps = {
    classname: undefined,
};

export default Header;
