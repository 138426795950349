import { FilterMode } from './models'

export default class Texture {
    _anisotropy: number
    _filterMode: FilterMode
    _mipmap: boolean
    _wrap: boolean
    dirty: boolean
    _gl: WebGLRenderingContext
    _texture: any
    width: number
    height: number

    constructor(gl: WebGLRenderingContext, width: number, height: number) {
        if (width === void 0) {
            width = 0
        }
        if (height === void 0) {
            height = 0
        }

        this._anisotropy = 1
        this._filterMode = 'BILINEAR'
        this._mipmap = false
        this._wrap = false
        this.dirty = false
        this.width = 0
        this.height = 0
        this._gl = gl
        this._texture = gl.createTexture()
        gl.bindTexture(gl.TEXTURE_2D, this._texture)
        gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, 1)
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, 1)
        this.updateFiltering()
        this.updateWrapping()
        this.setSize(width, height)
    }

    public get texture() {
        return this._texture
    }

    public get anisotropy() {
        return this._texture
    }

    public set anisotropy(value: number) {
        if (this._anisotropy != value) {
            this._anisotropy = value
            var gl: WebGLRenderingContext = this._gl
            var extension =
                gl.getExtension('EXT_texture_filter_anisotropic') ||
                gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
                gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic')
            if (extension) {
                gl.bindTexture(gl.TEXTURE_2D, this._texture)
                gl.texParameterf(
                    gl.TEXTURE_2D,
                    extension.TEXTURE_MAX_ANISOTROPY_EXT,
                    Math.min(
                        this._anisotropy,
                        gl.getParameter(
                            extension.MAX_TEXTURE_MAX_ANISOTROPY_EXT
                        )
                    )
                )
                this.dirty = true
            } else {
                console.warn('Anisotropic filtering is not supported.')
            }
        }
    }

    public get filterMode() {
        return this._filterMode
    }

    public set filterMode(value: FilterMode) {
        if (this._filterMode != value) {
            this._filterMode = value
            this.dirty = true
            this.updateFiltering()
        }
    }

    public get mimap() {
        return this._mipmap
    }

    public set mimap(value: boolean) {
        if (this._mipmap != value) {
            this._mipmap = value
            this.dirty = true
            this.updateFiltering()
        }
    }

    public get wrap() {
        return this._wrap
    }

    public set wrap(value: boolean) {
        if (this._wrap != value) {
            this._wrap = value
            this.dirty = true
            this.updateWrapping()
        }
    }

    /**
     * Draws the specified object to the texture and updates the texture size to match.
     */
    public drawTexture(image: any): void {
        var gl: WebGLRenderingContext = this._gl
        gl.bindTexture(gl.TEXTURE_2D, this._texture)
        gl.texImage2D(
            gl.TEXTURE_2D,
            0,
            gl.RGBA,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            image
        )
        if (this._mipmap) {
            gl.generateMipmap(gl.TEXTURE_2D)
        }
        this.width = image.videoWidth || image.width
        this.height = image.videoHeight || image.height
        this.dirty = true
    }

    /**
     * Sets the texture size and clears the pixel data.
     */
    public setSize(width: number, height: number): void {
        var gl: WebGLRenderingContext = this._gl
        gl.bindTexture(gl.TEXTURE_2D, this.texture)
        gl.texImage2D(
            gl.TEXTURE_2D,
            0,
            gl.RGBA,
            width,
            height,
            0,
            gl.RGBA,
            gl.UNSIGNED_BYTE,
            null
        )
        this.width = width
        this.height = height
    }

    /**
     * Dispose of unmanaged resources.
     */
    public dispose(): void {
        if (this._texture) {
            this._gl.deleteTexture(this._texture)
            this._texture = null
        }
    }

    public updateFiltering(): void {
        var gl: WebGLRenderingContext = this._gl
        gl.bindTexture(gl.TEXTURE_2D, this._texture)
        gl.texParameteri(
            gl.TEXTURE_2D,
            gl.TEXTURE_MAG_FILTER,
            this._filterMode == 'NEAREST' ? gl.NEAREST : gl.LINEAR
        )
        gl.texParameteri(
            gl.TEXTURE_2D,
            gl.TEXTURE_MIN_FILTER,
            this._mipmap
                ? this._filterMode == 'NEAREST'
                    ? gl.NEAREST_MIPMAP_NEAREST
                    : this._filterMode == 'BILINEAR'
                    ? gl.LINEAR_MIPMAP_NEAREST
                    : gl.LINEAR_MIPMAP_LINEAR
                : this._filterMode == 'NEAREST'
                ? gl.NEAREST
                : gl.LINEAR
        )
        if (this._mipmap && this.width > 0 && this.height > 0) {
            gl.generateMipmap(gl.TEXTURE_2D)
        }
    }

    public updateWrapping() {
        var gl: WebGLRenderingContext = this._gl
        gl.bindTexture(gl.TEXTURE_2D, this._texture)
        gl.texParameteri(
            gl.TEXTURE_2D,
            gl.TEXTURE_WRAP_S,
            this._wrap ? gl.REPEAT : gl.CLAMP_TO_EDGE
        )
        gl.texParameteri(
            gl.TEXTURE_2D,
            gl.TEXTURE_WRAP_T,
            this._wrap ? gl.REPEAT : gl.CLAMP_TO_EDGE
        )
    }
}
