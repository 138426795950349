import React from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import Button, { ButtonProps } from 'components/Button';

import './styles.scss';

type LanguageButtonProps = {
    code: string;
    label?: string;
};

const LanguageButton: React.FC<ButtonProps & LanguageButtonProps> = ({
    code,
    className,
    label,
}) => (
    <Button className={clsx('language-button', className)}>
        <Link to={`/${code}/`}>
            <div className="container">
                <div className="label">{label}</div>
            </div>
        </Link>
    </Button>
);

LanguageButton.defaultProps = {
    label: undefined,
};

export default LanguageButton;
