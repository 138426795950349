import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ParametersSettingsState } from 'types/parametersSettings';

const initialState: ParametersSettingsState = {
    configPath: '/',
    language: '',
};

export const parametersSettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<string>) => {
            state.configPath = action.payload;
        },
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
    },
});

export default parametersSettingsSlice.reducer;
