import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import styleVariables from 'assets/styles/_variables.module.scss';
import ImageColorizeComponent from 'components/ImageColorize';
import RoomContainer from 'components/RoomContainer';
import useViewport, { ViewportContext } from 'hooks/viewport';
import { getWallSectionColors } from 'selectors/app';
import { selectFullConfig } from 'selectors/config';
import { Config } from 'types/config';

import 'swiper/css';
import 'swiper/css/navigation';

import './styles.scss';

const RoomColorizerSwiper: React.FC = () => {
    const dispatch = useAppDispatch();
    const viewport: ViewportContext = useViewport();

    const jsonConfigs: Config = useAppSelector<Config>(selectFullConfig);
    const wallSectionColor: string[] =
        useAppSelector<string[]>(getWallSectionColors);

    const pageWidthSFallback: number = 1000;

    const handleSlideChange = (swiper: SwiperClass) => {
        dispatch(appActions.setSwiperActiveIndex(swiper.activeIndex));
    };

    return (
        <Swiper onSlideChange={handleSlideChange}>
            {jsonConfigs.Rooms.slice(0, 5).map(
                (roomConfig, roomConfigIndex) => (
                    <SwiperSlide key={roomConfig[roomConfigIndex]}>
                        <div className="colorizer-instance-container">
                            <ImageColorizeComponent
                                config={`config/${roomConfig}`}
                                index={roomConfigIndex}
                                wall1Color={wallSectionColor[0]}
                                wall2Color={wallSectionColor[1]}
                                wall3Color={wallSectionColor[2]}
                            />
                        </div>
                    </SwiperSlide>
                ),
            )}
            {viewport.orientation === 'desktop' &&
                viewport.width >=
                    (parseInt(styleVariables.pageWidthS, 10) ||
                        pageWidthSFallback) && (
                <RoomContainer
                    wall1Color={wallSectionColor[0]}
                    wall2Color={wallSectionColor[1]}
                    wall3Color={wallSectionColor[2]}
                />
            )}
        </Swiper>
    );
};

export default RoomColorizerSwiper;
