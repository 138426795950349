import i18next, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { Config } from 'types/config';

const fallbackLng = 'en';

export interface LanguageDescription {
    code: string;
    name: string;
    logo: string;
    client: string;
}

function initialLng(availableLanguages: string[], detectedLanguage: string) {
    if (availableLanguages.includes(detectedLanguage)) {
        return detectedLanguage;
    }
    return fallbackLng;
}

function getCurrentLanguage() {
    const lng = i18next.language;

    if (lng === undefined) return fallbackLng;

    if (!lng.includes('-')) {
        return lng;
    }

    return lng.split('-')[0];
}

function getLanguages(config: Config): LanguageDescription[] {
    if (!config?.i18n || typeof config.i18n !== 'object') {
        throw new Error('config.i18n was not an object');
    }

    return (Object.keys(config.i18n) || []).map(
        (languageKey: string): LanguageDescription => ({
            code: languageKey,
            name: config.i18n[languageKey]?.name,
            logo: config.i18n[languageKey]?.iconUrl,
            client: config.i18n[languageKey]?.client,
        }),
    );
}

function configToi18nextResources(config: Config): Resource {
    if (!config?.i18n || typeof config.i18n !== 'object') {
        throw new Error('config.i18n was not an object');
    }

    return Object.entries(config.i18n).reduce((acc: Resource, curr) => {
        const [lng, keyValueObj] = curr;

        const newAcc: Resource = { ...acc };
        newAcc[lng] = {
            translation: keyValueObj.translations,
        };

        return newAcc;
    }, {});
}

function changeLanguage(language: string) {
    i18next.changeLanguage(language);
}

function init(config: Config) {
    const resources = configToi18nextResources(config);

    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init(
            {
                resources,
                fallbackLng,
                nsSeparator: '<!><!><!><!><!>',
                keySeparator: '<!><!><!><!><!>',
            },
            (err) => {
                if (err) {
                    console.error('Error while i18next init:', err);
                }
            },
        )
        .then(() => {
            changeLanguage(
                initialLng(Object.keys(resources), getCurrentLanguage()),
            );
        });
}

function translate(key: string) {
    return i18next.t(key);
}

const i18n = {
    init,
    changeLanguage,
    getCurrentLanguage,
    getLanguages,
    translate,
};

export default i18n;
