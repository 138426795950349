import React from 'react';

import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const PointRight: React.FC<IconProps> = ({
    className,
    height = 20,
    width = 20,
}) => (
    <IconBase
        className={className}
        height={height}
        viewBox="0 0 17 6"
        width={width}
    >
        <path d="M0 2.74121L12.336 2.74124" stroke="white" strokeWidth="0.856667" />
        <circle cx="12.3358" cy="2.74133" r="2.74133" transform="rotate(90 12.3358 2.74133)" fill="white" />
    </IconBase>
);

export default PointRight;
