/* eslint-disable no-console */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'app/hook';
import { selectColorConfig } from 'selectors/config';
import { ColorConfig } from 'types/config';

import './styles.scss';

type ColorBoxProps = {
    onWallClick: (colorID?: string) => void;
    colorID?: string;
}

const ColorBox: React.FC<ColorBoxProps> = ({
    onWallClick,
    colorID,
}) => {
    const { t } = useTranslation();
    const selectedColor: ColorConfig | undefined =
        useAppSelector<ColorConfig | undefined>(selectColorConfig(colorID));

    const handleClick = () => {
        if (!onWallClick) {
            return;
        }
        onWallClick(colorID);
    };

    const getTextColor = (hex: string | undefined) => {
        if (hex === undefined) return '#000000';
        const red = parseInt(hex.substring(0, 2), 16);
        const green = parseInt(hex.substring(2, 4), 16);
        const blue = parseInt(hex.substring(4, 6), 16);
        const colorValue = Math.floor(
            Math.sqrt(
                0.2999 * red ** 2 + 0.587 * green ** 2 + 0.114 * blue ** 2,
            ),
        );
        return (colorValue < 135) ? '#FFFFFF' : '#000000';
    };

    return (
        <div
            className="color-box"
            onClick={(handleClick)}
            role="presentation"
            style={{
                backgroundColor: `#${selectedColor?.RGB}`,
            }}
        >
            <p
                className="color-name"
                style={{
                    color: getTextColor(`${selectedColor?.RGB}`),
                }}
            >
                {selectedColor?.ID ? t(selectedColor?.ID) : selectedColor?.Name}
            </p>
        </div>
    );
};

ColorBox.defaultProps = {
    colorID: undefined,
};

export default ColorBox;
