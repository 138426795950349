import axios, { AxiosResponse } from 'axios';

import { ConfigNotFoundError, ConfigParseError } from 'errors/configErrors';
import { Config } from 'types/config';
import { getParametersSettings } from 'utils/parametesSettings';

const get = async (): Promise<Config | undefined> => {
    const parametersSettings = getParametersSettings();
    // const configUrl = `${parametersSettings.configPath}config/levis-ambiance.json`;
    const configUrl = `${parametersSettings.configPath}config/flexa-creations.json`;

    if (!configUrl) {
        throw new ConfigNotFoundError(configUrl);
    }

    let response: AxiosResponse<Config>;

    try {
        response = await axios.get<Config>(configUrl);
    } catch (err) {
        throw new ConfigNotFoundError(configUrl);
    }

    if (!response?.data) {
        throw new ConfigNotFoundError(configUrl);
    }

    if (typeof response.data !== 'object') {
        throw new ConfigParseError(configUrl);
    }

    return response.data;
};

const configService = {
    get,
};

export default configService;
