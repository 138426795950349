import React from 'react';

import clsx from 'clsx';

import Button, { ButtonProps } from 'components/Button';
import Close from 'components/Icon/Close';

type CloseButtonProps = {
    fill?: string;
    stroke?: string;
}

const CloseButton: React.FC<ButtonProps & CloseButtonProps> = ({
    fill,
    stroke,
    className,
    onClick,
}) => (
    <Button className={clsx('close-button', className)} onClick={onClick}>
        <div className="icon-container">
            <Close fill={fill} stroke={stroke} />
        </div>
    </Button>
);

CloseButton.defaultProps = {
    fill: '',
    stroke: '',
};

export default CloseButton;
