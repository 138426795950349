import React from 'react';

import clsx from 'clsx';

import Button, { ButtonProps } from 'components/Button';
import ArrowLeftColorPalette from 'components/Icon/ArrowLeftColorPalette';
import './styles.scss';

const ArrowLeftColorPaletteButton: React.FC<ButtonProps> = ({
    className,
    onClick,
}) => (
    <Button className={clsx('arrow-left-color-palette-button', className)} onClick={onClick}>
        <div className="icon-container">
            <ArrowLeftColorPalette />
        </div>
    </Button>
);

export default ArrowLeftColorPaletteButton;
