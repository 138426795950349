import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { throttle } from 'throttle-debounce';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import CloseButton from 'components/Button/CloseButton';
import PointDown from 'components/Icon/PointDown';
import PointUp from 'components/Icon/PointUp';
import { getLanguage } from 'selectors/app';
import { selectClientName, selectLanguages } from 'selectors/config';
import i18n, { LanguageDescription } from 'utils/i18next';

import './styles.scss';

const ColorSelectionTutorialOverlay: React.FC = () => {
    const dispatch = useAppDispatch();

    const getOrientation = () => {
        if (!navigator.maxTouchPoints) return 'desktop';
        return window.innerHeight > window.innerWidth
            ? 'portrait'
            : 'landscape';
    };

    const [orientation, setOrientation] = useState<string>(getOrientation());
    const [desktopValue, setDesktopValue] = useState<string>('');
    const [swiperControl, setSwiperControl] = useState<SwiperClass>();
    const [languageCode, setLanguageCode] = useState<string>('');
    const [client, setClient] = useState<string>('');

    const handleResize = throttle(1000, () => {
        // eslint-disable-next-line no-nested-ternary
        setOrientation(getOrientation());
    });

    const [currentPage, setCurrentPage] = useState<number>(1);

    const clientName: LanguageDescription[] = useAppSelector(selectClientName);
    const languages: LanguageDescription[] = useAppSelector(selectLanguages);
    const selectedLanguage = useAppSelector<string>(getLanguage);

    const handlePrevButtonClick = () => {
        if (currentPage === 1) {
            setCurrentPage(3);
            return;
        }
        swiperControl?.slidePrev();
        setCurrentPage(currentPage - 1);
    };
    const handleNextButtonClick = () => {
        if (currentPage === 3) {
            dispatch(appActions.setHasShownColorSelectionTutorial(true));
            return;
        }
        swiperControl?.slideNext();
        setCurrentPage((currentPage % 3) + 1);
    };

    const handleSlideChange = (swiper: SwiperClass) => {
        setCurrentPage(swiper.activeIndex + 1);
    };

    useEffect(() => {
        const currentLanguage: string = i18n.getCurrentLanguage();

        const languageDescription: LanguageDescription | undefined =
            languages.find(
                (language: LanguageDescription): boolean =>
                    language.code === currentLanguage,
            );

        if (languageDescription) {
            setLanguageCode(`-${languageDescription.code}`);
        }
    }, [selectedLanguage]);

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
        setClient(`-${clientName[0].client}`);
    }, []);

    useEffect(() => {
        if (orientation === 'desktop') {
            setDesktopValue(`-${orientation}`);
            return;
        }
        setDesktopValue('');
    }, [orientation]);

    return (
        <>
            <div className={clsx('white-background color-selection', {
                'desktop-view': orientation === 'desktop',
            })}
            />
            <div className="color-selection-tutorial">
                <CloseButton
                    fill="white"
                    stroke="white"
                    onClick={() => {
                        dispatch(appActions.setHasShownColorSelectionTutorial(true));
                    }}
                />
                <div className={clsx('image-container', {
                    'desktop-size': orientation === 'desktop',
                })}
                >
                    <Swiper
                        onInit={(ev) => { setSwiperControl(ev); }}
                        onSlideChange={handleSlideChange}
                    >
                        <SwiperSlide key={1}>
                            <div id={`color-selection-tutorial${desktopValue}${client}${languageCode}-image-1`} />
                            <div className={`tutorial-text text-page-1 ${desktopValue}`}>
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>COLOR_SELECTION_TUTORIAL_TEXT_1_PAGE_1</Trans>
                                    </div>
                                    <PointUp className="point point-up" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={2}>
                            <div id={`color-selection-tutorial${desktopValue}${client}${languageCode}-image-2`} />
                            <div className={`tutorial-text text-page-2 ${desktopValue}`}>
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>COLOR_SELECTION_TUTORIAL_TEXT_1_PAGE_2</Trans>
                                    </div>
                                    <PointDown className="point point-down" />
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide key={3}>
                            <div id={`color-selection-tutorial${desktopValue}${client}${languageCode}-image-3`} />
                            <div className={`tutorial-text text-page-3 ${desktopValue}`}>
                                <div className="p-r">
                                    <div className="text-container">
                                        <Trans>COLOR_SELECTION_TUTORIAL_TEXT_1_PAGE_3</Trans>
                                    </div>
                                    <PointDown className="point point-down" />
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className={clsx('tutorial-description', {
                    'desktop-description': orientation === 'desktop',
                })}
                >
                    <Trans>COLOR_SELECTION_TUTORIAL_DESCRIPTION</Trans>
                </div>
                <div className={clsx('controls', {
                    'desktop-controls': orientation === 'desktop',
                })}
                >
                    <div
                        className={clsx('prev-button', {
                            inactive: currentPage === 1,
                        })}
                        onClick={handlePrevButtonClick}
                        role="presentation"
                    >
                        <Trans>COLOR_SELECTION_TUTORIAL_PREV_BUTTON</Trans>
                    </div>
                    <div className="dots-container">
                        <div
                            className={clsx('dot dot-1', {
                                active: currentPage === 1,
                            })}
                            onClick={() => swiperControl?.slideTo(0)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-2', {
                                active: currentPage === 2,
                            })}
                            onClick={() => swiperControl?.slideTo(1)}
                            role="presentation"
                        />
                        <div
                            className={clsx('dot dot-3', {
                                active: currentPage === 3,
                            })}
                            onClick={() => swiperControl?.slideTo(2)}
                            role="presentation"
                        />
                    </div>
                    <div
                        className="next-button"
                        onClick={handleNextButtonClick}
                        role="presentation"
                    >
                        {currentPage !== 3 &&
                            <Trans>COLOR_SELECTION_TUTORIAL_NEXT_BUTTON</Trans>
                        }
                        {currentPage === 3 &&
                            <Trans>COLOR_SELECTION_TUTORIAL_START_BUTTON</Trans>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ColorSelectionTutorialOverlay;
