/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect } from 'react';

import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import ColorPalettePage from 'components/ColorPalettePage';
import Header from 'components/Header';
import LanguageSelector from 'components/LanguageSelector';
import NavigationPanel from 'components/NavigationPanel';
import RoomColorizerPage from 'components/RoomColorizerPage';
import useViewport, { ViewportContext } from 'hooks/viewport';
import { getIsColorPalettePage, getLanguage } from 'selectors/app';
import { selectLanguages } from 'selectors/config';
import i18n, { LanguageDescription } from 'utils/i18next';

import 'swiper/css';
import 'swiper/css/navigation';
import './styles.scss';

const App: React.FC = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const viewport: ViewportContext = useViewport();
    const languages: LanguageDescription[] =
        useAppSelector<LanguageDescription[]>(selectLanguages);
    const currentLanguage = useAppSelector<string>(getLanguage);

    const isColorPalettePage: boolean = useAppSelector<boolean>(
        getIsColorPalettePage,
    );

    useEffect(() => {
        if (languages.length !== 1) return;

        i18n.changeLanguage(languages[0].code);
        dispatch(appActions.setLanguage(languages[0].code));
    }, [languages]);

    useEffect(() => {
        if (!languages.length || !params.language) {
            return;
        }

        const selectedLanguage: LanguageDescription | undefined =
            languages.find(
                (language: LanguageDescription): boolean =>
                    language.code === params.language,
            );

        if (!selectedLanguage) {
            return;
        }

        i18n.changeLanguage(selectedLanguage.code);
        dispatch(appActions.setLanguage(selectedLanguage.code));
    }, [params.language, languages]);

    return (
        <div
            className={clsx('akzo-container', {
                'mobile-landscape':
                    viewport.orientation === 'landscape' && !isColorPalettePage,
            })}
        >
            <Header
                classname={clsx({
                    desktop: viewport.orientation === 'desktop',
                })}
            />

            {!currentLanguage && languages?.length > 1 && <LanguageSelector />}

            <section
                className={clsx({
                    'd-none': !currentLanguage,
                })}
            >
                <NavigationPanel />

                {isColorPalettePage && <ColorPalettePage />}

                <RoomColorizerPage
                    className={clsx({
                        'd-none': isColorPalettePage,
                    })}
                />
            </section>
        </div>
    );
};

export default App;
