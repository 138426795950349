/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';

import clsx from 'clsx';
import { useSwiper } from 'swiper/react';

import './styles.scss';

import { useAppSelector } from 'app/hook';
import ImageColorizeComponent from 'components/ImageColorize';
import { getSwiperActiveIndex } from 'selectors/app';
import { selectFullConfig } from 'selectors/config';
import { Config } from 'types/config';

type RoomContainerProps = {
    wall1Color?: string;
    wall2Color?: string;
    wall3Color?: string;
}

const RoomContainer: React.FC<RoomContainerProps> = (
    { wall1Color, wall2Color, wall3Color },
) => {
    const jsonConfigs: Config = useAppSelector<Config>(selectFullConfig);

    const swiper = useSwiper();

    const handleClick = (index: number) => {
        swiper.slideTo(index);
    };

    const swiperActiveIndex: number = useAppSelector<number>(getSwiperActiveIndex);

    return (
        <div className="room-container">
            {jsonConfigs.Rooms.slice(0, 5).map((roomConfig, roomConfigIndex) => (
                <div
                    className={clsx('colorizer-instance-container', { active: roomConfigIndex === swiperActiveIndex })}
                    id={`room-container-${roomConfigIndex}`}
                    key={roomConfig[roomConfigIndex]}
                    onClick={() => {
                        handleClick(roomConfigIndex);
                    }}
                    role="presentation"
                >
                    <ImageColorizeComponent
                        config={`config/${roomConfig}`}
                        index={roomConfigIndex}
                        shouldGenerateDownloadableImage={false}
                        wall1Color={wall1Color}
                        wall2Color={wall2Color}
                        wall3Color={wall3Color}
                    />
                </div>
            ))
            }
        </div>
    );
};

export default RoomContainer;
