import React from 'react';

import './styles.scss';

import clsx from 'clsx';

import Button, { ButtonProps } from 'components/Button';

type EndScreenButtonProps = {
    onClick: (value: boolean) => void;
    label?: string;
}

const EndScreenButton: React.FC<ButtonProps & EndScreenButtonProps> = ({
    onClick,
    className,
    label,
}) => (
    <Button className={clsx('end-screen-button', className)} onClick={onClick}>
        <div className="container">
            <div className="label">
                {label}
            </div>
        </div>
    </Button>
);

EndScreenButton.defaultProps = {
    label: undefined,
};

export default EndScreenButton;
